import React, {useEffect} from "react";
import {Button} from 'antd';
import * as qs from "qs";
import {isApp, ReactNativeMessage, safeJSONParse} from "../../helpers";
import {useTranslation} from "react-i18next";
import {useLocation, useNavigate} from "react-router-dom";
import {GoogleLogo} from "../../icons";
import Icon from "@ant-design/icons";
import { useGoogleLogin } from '@react-oauth/google';

const GoogleButton = ({isLoading, isDisabled, type, callback}) => {
    const is_app = isApp();
    const {t} = useTranslation();
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        if (is_app) {
            const body = isApp('ios') ? window : document;

            body.addEventListener("message", handleNativeLogin);

            return (() => {
                body.removeEventListener("message", handleNativeLogin);
            })
        }
    }, [is_app]);

    // React native login handler
    const handleNativeLogin = (e) => {
        if (e && e.data) {
            const data = safeJSONParse(e.data);
            const token = data.google_token;
            if (token) {
                callback({accessToken: token});
            }
        }
    };

    const onClick = () => {
        const isFacebookBrowser = navigator.userAgent.includes("FBAN") || navigator.userAgent.includes("FBAV");

        if (isFacebookBrowser) {
            alert(t('google-login.limit'));
            return false;
        }

        // Handle App fb login in app native
        if (isApp()) {
            ReactNativeMessage('AUTHORIZE_GOOGLE_FIT');

            return false;
        }

        // Add custom param in order on return to auto load facebook
        navigate('?google-login=true');

        googleLogin();
    };

    const googleLogin = useGoogleLogin({
        onSuccess: tokenResponse => callback({accessToken: tokenResponse.access_token}),
    });

    return (
        <Button block style={{marginTop: 16}} size="large"
                className="login-form-button"
                onClick={onClick}
                loading={isLoading}
                disabled={isDisabled}
                icon={<Icon component={GoogleLogo}/>}>{t(type + '.google')}
        </Button>
    )
};

export default GoogleButton;
