import {Avatar, Button, Card, Col, message, Progress, Row, Space, Tag, Tooltip, Typography} from "antd";
import {
    AntDesignOutlined,
    InfoCircleOutlined,
    LockOutlined,
    UsergroupAddOutlined,
    UserOutlined
} from "@ant-design/icons";
import React from "react";
import {Link, useNavigate} from "react-router-dom";
import moment from "moment/moment";
import CountdownTimer from "./countdown-timer";
import {useTranslation} from "react-i18next";

const {Title, Text} = Typography;

const ChallengeCard = ({challenge, setShowPurchase}) => {
    const {t} = useTranslation();
    const navigate = useNavigate();
    const startDate = challenge.begin_date ? moment(challenge.begin_date) : moment("2025-01-01");
    const endDate = challenge.end_date ? moment(challenge.end_date) : moment("2025-02-28");
    const today = moment(); // Assuming today is the 3rd day of challenge
    const totalDays = endDate.diff(startDate, "days");
    const daysPassed = today.diff(startDate, "days");
    const progressPercent = (daysPassed / totalDays) * 100;

    const joinChallenge = () => {
        navigate('/challenges/' + challenge.id);
    }

    return (
        <Card bordered={false} className="challenge-card-wrapper" cover={challenge.image ? (
            <img style={{height: 160, objectFit: "cover"}}
                 src={challenge.image}/>
        ) : null} title={''}>

            <Space direction="vertical" style={{width: "100%"}}>
                <Row type="flex" justify="space-between">
                    <Col span={24}>
                        <Title style={{margin: 0, whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis'}}
                               level={5}>{challenge.name}</Title>
                    </Col>
                </Row>

                {false && (
                    <Progress strokeColor={{'0%': '#ee7752', '50%': '#e73c7e'}}
                              percent={parseInt(progressPercent)}
                              percentPosition={{type: 'hidden'}}
                              status={progressPercent < 100 ? "active" : "success"}/>
                )}

                <Row type="flex" justify="space-between">
                    {challenge.type === 'steps' && !challenge.is_paid && (
                        <div>
                            <UsergroupAddOutlined/> {challenge.participants_count}
                        </div>
                    )}
                </Row>

                <Row type="flex" justify="center" style={{gap: 8}}>
                    {challenge.has_access ? (
                        <Space>
                            <Button block onClick={joinChallenge} type={"primary"}>Ava väljakutse</Button>

                            <Link to={"/sales-page/" + challenge.id}>
                                <Button block type="default" icon={<InfoCircleOutlined/>}>{t('challenge.info')}</Button>
                            </Link>
                        </Space>
                    ) : (
                        <Space>
                            <Button block onClick={() => setShowPurchase(challenge)}
                                    type={"default"}><LockOutlined/> Tasuline</Button>

                            <Link to={"/sales-page/" + challenge.id}>
                                <Button block type="default" icon={<InfoCircleOutlined/>}>{t('challenge.info')}</Button>
                            </Link>
                        </Space>
                    )}
                </Row>
            </Space>
        </Card>
    )
}

export default ChallengeCard;