import {Alert} from "antd";
import {getCookie, setCookie} from "../../helpers";
import React from "react";
import {useTranslation} from "react-i18next";

const CookbookTip = () => {
    const {t} = useTranslation();

    const close = () => {
        setCookie('cookbook_tip_closable', true, 9999)
    }

    if (getCookie('cookbook_tip_closable')) {
        return false;
    }

    return (
            <Alert closable onClose={close} style={{marginTop: 12}} message={t('cookbook.tip')}
                   type="info" showIcon/>
    )
}

export default CookbookTip;