import {useTranslation} from "react-i18next";
import {Alert, Tag, Tooltip} from "antd";
import Icon, {
    HeartFilled,
    HeartOutlined,
    UserOutlined,
    ExclamationCircleOutlined,
    MessageOutlined
} from "@ant-design/icons";
import React from "react";
import {Link} from "react-router-dom";
import {useSelector} from "react-redux";
import {isEmpty} from "../../helpers";
import AuthorTag from "../recipes/author-tag";

const RecipeTags = ({meal, recipe, showOnImage, showTags, isLiked}) => {
    const {t} = useTranslation();
    const recipeData = recipe ? recipe : meal.recipe;
    const user = useSelector(state => state.user.data);
    const showGramsAlways = !isEmpty(user) ? !!user.intolerances.has_diabetes : false

    const getMacroValue = (name) => {
        const showGrams = user.macroSettings === 'grams' || showGramsAlways;

        if (name === 'fiber') {
            if (recipeData.fiber) {
                return parseInt(recipeData.fiber) + ' g';
            } else {
                return parseInt(recipeData.macros[name]) + ' g';
            }
        }

        const unit = showGrams ? 'g' : '%';
        const value = showGrams ? parseInt(recipeData.macros[name]) : parseInt(recipeData.macros[name + 'Percentage']);

        return value + (unit === 'g' ? ' ' : '') + unit;
    }


    if (showOnImage) {
        return (
            <div className="recipe-tags">
                {meal && meal.energy > 0 && (
                    <Tag bordered={false} className="ant-tag-default">
                        {meal.energy} {t('unit.kcal')}
                    </Tag>
                )}

                {!meal && recipeData.likes_count > 0 && (
                    <Tag bordered={false} className="ant-tag-default"
                         icon={isLiked ? <HeartFilled style={{color: "red"}}/> : <HeartOutlined/>}>
                        {recipeData.likes_count ? recipeData.likes_count : 0}
                    </Tag>
                )}

                {recipeData.preparation_time > 0 && (
                    <Tag bordered={false} className="ant-tag-default">
                        {recipeData.preparation_time} {t('unit.min')}
                    </Tag>
                )}
            </div>
        )
    }

    return (
        <div className="recipe-info-wrapper">
            {showTags && (
                <div>
                    {meal && meal.energy > 0 && (
                        <Tag bordered={false} className="ant-tag-default">
                            {meal.energy} {t('unit.kcal')}
                        </Tag>
                    )}

                    {!meal && recipeData.likes_count > 0 && (
                        <Tag bordered={false} className="ant-tag-default"
                             icon={isLiked ? <HeartFilled style={{color: "red"}}/> : <HeartOutlined/>}>
                            {recipeData.likes_count ? recipeData.likes_count : 0}
                        </Tag>
                    )}

                    {recipeData.preparation_time > 0 && (
                        <Tag bordered={false} className="ant-tag-default">
                            {recipeData.preparation_time} {t('unit.min')}
                        </Tag>
                    )}

                    <AuthorTag recipe={recipeData}/>
                </div>
            )}

            {recipeData.macros && (user.macroSettings !== 'off' || showGramsAlways) && (
                <div className="recipe-nutridata-row">
                    <span>{t('unit.r')} {getMacroValue('fat')}</span>
                    <span>{t('unit.sv')} {getMacroValue('carbohydrate')}</span>
                    <span>{t('unit.v')} {getMacroValue('protein')}</span>

                    {(recipeData.fiber > 0 || recipeData.macros.fiber > 0) && showTags && (
                        <span>{t('unit.f')} {getMacroValue('fiber')}</span>
                    )}
                </div>
            )}
        </div>
    )
}

export default RecipeTags;