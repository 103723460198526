import React, {useEffect, useState} from "react";
import Api from "../api";
import {
    Avatar,
    Button,
    Card,
    Checkbox,
    Col,
    Divider,
    Form,
    InputNumber,
    message,
    Modal,
    Radio,
    Row,
    Select,
    Skeleton,
    Typography
} from 'antd';
import {dateDifference, filterObject, isEmpty, trackEvent, trackUser,} from "../helpers";
import Icon, {EditOutlined, ExclamationCircleFilled} from "@ant-design/icons";
import {withTranslation} from 'react-i18next';
import CountUp from "react-countup";
import {getNewRecipes, getUserData} from "../actions";
import {useDispatch, useSelector} from "react-redux";
import {useLocation} from "react-router-dom";
import {anything_dish, pescatariang_dish, vegan_dish, vegetarian_dish} from "../assets";

const {Meta} = Card;
const {confirm} = Modal;
const {Title} = Typography;
const {Option} = Select;

const MealSettings = ({t}) => {
    const dispatch = useDispatch();
    const [isLoading, setIsLoading] = useState(true);
    const user = useSelector(state => state.user.data);
    const [isFormChanged, setFormChanged] = useState(false);
    const [initialData, setInitialData] = useState({});
    const [initialRecipeCount, setInitialRecipeCount] = useState(0);
    const [currentData, setCurrentData] = useState({});
    const [currentRecipeCount, setCurrentRecipeCount] = useState(0);
    const [isMealsResetModalVisible, showMealsResetModal] = useState(false);
    const [allAllergens, setAllAllergens] = useState([]);
    const [showManualTdee, setShowManualTdee] = useState(false);
    const [showManualMacros, setShowManualMacros] = useState(false);
    const [calCoef, setCalCoef] = useState(user.cal_coef);
    const allPreferenceOptions = [
        {
            id: "all",
            title: t('onboarding-flow.plan.all'),
            description: t('onboarding-flow.plan.all-text'),
        },
        {
            id: "pescatarian",
            title: t('onboarding-flow.plan.pescatarian'),
            description: t('onboarding-flow.plan.pescatarian-text'),
        },
        {
            id: "vegetarian",
            title: t('onboarding-flow.plan.vegetarian'),
            description: t('onboarding-flow.plan.vegetarian-text'),
        },
        {
            id: "vegan",
            title: t('onboarding-flow.plan.vegan'),
            description: t('onboarding-flow.plan.vegan-text'),
        },
    ];
    const allRecipeSettings = [
        {
            id: "all",
            title: t('settings.meals.type-all'),
            description: t('settings.meals.type-all-desc'),
        },
        {
            id: "basic",
            title: t('settings.meals.type-basic'),
            description: t('settings.meals.type-basic-desc'),
        },
        {
            id: "fast",
            title: t('settings.meals.type-fast'),
            description: t('settings.meals.type-fast-desc'),
        },
        {
            id: "simple",
            title: t('settings.meals.type-simple'),
            description: t('settings.meals.type-simple-desc'),
        },
        {
            id: "cheap",
            title: t('settings.meals.type-cheap'),
            description: t('settings.meals.type-cheap-desc'),
        },
    ]

    const dailyKcal = user.tdee - (user.tdee / 4 / 2);
    const originalKcal = user.original_tdee ? user.original_tdee : dailyKcal;


    const location = useLocation();
    const [form] = Form.useForm();

    useEffect(() => {
        window.scrollTo(0, 0);

        trackEvent('opened meal settings', {
            source: location.state ? location.state.from : ''
        });

        Api.get('/v2/user/meal-preferences').then(response => {
            setInitialData(response.data.user);
            setInitialRecipeCount(response.data.meta.currentRecipeCount);

            setCurrentData(response.data.user);
            setCurrentRecipeCount(response.data.meta.currentRecipeCount);

            // Sort alphabetically by title
            let sortedAllergens = response.data.meta.allergenOptions.sort((a, b) => a.title.localeCompare(b.title));

            setAllAllergens(sortedAllergens);
            setIsLoading(false);
        });
    }, []);

    useEffect(() => {
        if (!isEmpty(user)) {
            setShowManualTdee(!!user.manual_tdee);

            if (user.manual_macros.manual_fat || user.manual_macros.manual_protein || user.manual_macros.manual_carb) {
                setShowManualMacros(true);
            }
        }
    }, [user])

    const onSaveClick = (e) => {
        if (currentRecipeCount == initialRecipeCount) {
            return saveSettings();
        }

        showConfirm();
    };

    const showConfirm = () => {
        confirm({
            title: t('menu.settings.meals.save-modal.confirm'),
            icon: <ExclamationCircleFilled/>,
            content: t('menu.settings.meals.save-modal.notice'),
            okText: t('common.yes'),
            cancelText: t('common.no'),
            onOk() {
                console.log('OK');
                saveSettings();
            },
            onCancel() {
                console.log('Cancel');
            },
        });
    };


    const saveSettings = () => {
        Api.post('/v2/user/meal-preferences', currentData).then(response => {
            sendTrackingData(initialData, currentData);

            if (initialRecipeCount != response.data.meta.currentRecipeCount) {
                Api.post('/v1/menu/random/all');
            }

            setFormChanged(false);
            showMealsResetModal(false);

            setInitialData(response.data.user);
            setInitialRecipeCount(response.data.meta.currentRecipeCount);

            setCurrentData(response.data.user);
            setCurrentRecipeCount(response.data.meta.currentRecipeCount);

            // Reload recipes incase allergens changed
            dispatch(getNewRecipes());

            saveUserTdee();
        });
    };

    const saveUserTdee = () => {
        let values = {
            manual_carb: form.getFieldValue('manual_carb'),
            manual_tdee: form.getFieldValue('manual_tdee'),
            manual_protein: form.getFieldValue('manual_protein'),
            manual_fat: form.getFieldValue('manual_fat'),
            goal: form.getFieldValue('goal'),
            cal_coef: calCoef,
        }

        // Validate carbs, protein, fat
        if (values['manual_carb'] || values['manual_protein'] || values['manual_fat']) {
            let total = values['manual_carb'] + values['manual_protein'] + values['manual_fat'];

            if (total !== 100 || !values['manual_protein'] || !values['manual_fat'] || !values['manual_carb']) {
                message.error(t('user.nutridata.error'))
                return false;
            }
        }

        if (!values.manual_tdee || !showManualTdee) {
            values.manual_tdee = null;
            values.manual_carb = null;
            values.manual_protein = null;
            values.manual_fat = null;
        } else {
            let original_value = user.tdee - (user.tdee / 4 / 2);

            values.original_tdee = original_value;
        }

        Api.put('/v1/user', values).then((res) => {
            message.success(t('daily-water.saved'));
            dispatch(getUserData());
        });
    }

    // Amplitude event tracking
    const sendTrackingData = (initialData, currentData) => {
        trackEvent('updated intolerances', {
            old_values: initialData.options.join(','), new_values: currentData.options.join(',')
        });

        let newAllergens = [];
        currentData.allergens.map(id => {
            let allergen = filterObject(allAllergens, 'id', id);

            if (allergen[0]) {
                newAllergens.push(allergen[0].title.toLowerCase())
            }
        });
        let oldAllergens = [];
        initialData.allergens.map(id => {
            let allergen = filterObject(allAllergens, 'id', id);

            if (allergen[0]) {
                oldAllergens.push(allergen[0].title.toLowerCase())
            }
        });

        trackEvent('updated allergens', {
            old_values: `${oldAllergens}`, new_values: `${newAllergens}`,
        });

        trackEvent('updated recipe setting', {
            old_value: initialData.recipeSetting, new_value: currentData.recipeSetting
        });

        trackUser({
            meal_plan: currentData.preference,
            recipe_setting: currentData.recipeSetting,

            food_dislikes: `${newAllergens}`,
            intolerances: `${currentData.intolerances}`,
            diabetes: currentData.options.has_diabetes,
        });
    };

    const onFormUpdate = (e) => {
        setFormChanged(true);

        let optionsData = form.getFieldValue('options');
        let restaurantMeals = form.getFieldValue('show_restaurant_meals');

        if (restaurantMeals) {
            if (!optionsData.includes('show_restaurant_meals')) {
                optionsData.push('show_restaurant_meals');
            }
        } else {
            optionsData = optionsData.filter(item => item !== 'show_restaurant_meals')
        }

        console.log(optionsData);

        const settingsData = {
            preference: form.getFieldValue('preference'),
            recipeSetting: form.getFieldValue('recipeSetting'),
            options: optionsData,
            allergens: form.getFieldValue('allergens'),
            macroSetting: form.getFieldValue('macroSetting'),
            include_favorites: form.getFieldValue('include_favorites')
        };

        setCurrentData(settingsData);

        Api.post('/v2/user/meal-preferences/recipe-count', settingsData).then((response) => {
            setCurrentRecipeCount(response.data.recipeCount);

            if (currentRecipeCount != response.data.recipeCount) {
                message.info(<span>{response.data.recipeCount} {t('onboarding-flow.counter.title')}</span>, 2);
            }
        });
    };

    const showTdeeWarning = () => {
        let userManualTdee = form.getFieldValue('manual_tdee') ? form.getFieldValue('manual_tdee') : user.manual_tdee;

        if (!userManualTdee) {
            return false;
        }

        if (showManualTdee) {
            if (originalKcal < userManualTdee) {

                let percentage = parseInt(originalKcal / userManualTdee * 100);

                if ((100 - percentage) > 20) {
                    return true;
                }
            }

            if (originalKcal > userManualTdee) {

                let percentage = parseInt(userManualTdee / originalKcal * 100);

                if ((100 - percentage) > 20) {
                    return true;
                }
            }
        }

        return false;
    }

    const isNewUser = () => {
        return (dateDifference(user.added) > -30);
    }

    return (
        <div className="meal-settings">
            {isLoading ? (
                <Row type="flex" justify="center">
                    <Col span={24}>
                        <Skeleton active={true}/>
                    </Col>
                </Row>
            ) : (
                <>
                    <Form
                        form={form}
                        onFinish={onSaveClick}
                        onChange={onFormUpdate}
                    >
                        <Form.Item name={"preference"} initialValue={initialData.preference}>
                            <Radio.Group style={{width: "100%"}}>
                                <Row className="preference-wrapper" type="flex" align="middle" gutter={[20, 20]}>
                                    {allPreferenceOptions.map(preferenceOption => {
                                        const getImage = () => {
                                            if (preferenceOption.id === 'vegan') {
                                                return vegan_dish;
                                            }

                                            if (preferenceOption.id === 'vegetarian') {
                                                return vegetarian_dish;
                                            }

                                            if (preferenceOption.id === 'pescatarian') {
                                                return pescatariang_dish;
                                            }

                                            if (preferenceOption.id === 'all') {
                                                return anything_dish;
                                            }
                                        }

                                        return (
                                            <Col key={preferenceOption.id} span={24} md={12}>
                                                <Radio value={preferenceOption.id}>
                                                    <Card className={"meal-plan-type-card"}>
                                                        <Meta
                                                            avatar={<Avatar src={getImage()}/>}
                                                            title={t('onboarding-flow.plan.' + preferenceOption.id)}
                                                            description={t('onboarding-flow.plan.' + preferenceOption.id + '-text')}
                                                        />
                                                    </Card>
                                                </Radio>
                                            </Col>
                                        )
                                    })}
                                </Row>
                            </Radio.Group>
                        </Form.Item>

                        <Divider orientation="left">
                            <Title style={{margin: 0}} level={4}>{t('menu.settings.recipes')}</Title>
                        </Divider>

                        <Form.Item name={"recipeSetting"} initialValue={initialData.recipeSetting}>
                            <Radio.Group style={{width: "100%"}}>
                                <Row type="flex" align="middle">
                                    {allRecipeSettings.map(setting => {
                                        return (
                                            <Col span={24} style={{marginBottom: 6}}>
                                                <Radio value={setting.id}>
                                                    <p style={{margin: 0}}>{setting.title} - {setting.description}</p>
                                                </Radio>
                                            </Col>
                                        )
                                    })}
                                </Row>
                            </Radio.Group>
                        </Form.Item>

                        <Divider orientation="left">
                            <Title style={{margin: 0}}
                                   level={4}>{t('additional-settings.title')}</Title>
                        </Divider>

                        <Form.Item
                            style={{marginBottom: "-5px"}}
                            name="include_favorites"
                            valuePropName="checked"
                            initialValue={initialData.include_favorites}>
                            <Checkbox
                                defaultChecked={initialData.include_favorites}>{t('settings.prefer.favorites')}</Checkbox>
                        </Form.Item>

                        <Form.Item
                            style={{marginBottom: "-5px"}}
                            name="show_restaurant_meals"
                            valuePropName="checked"
                            initialValue={initialData.options.includes('show_restaurant_meals')}>
                            <Checkbox
                                value="show_restaurant_meals">{t('settings.meals.include-premade-meals')}</Checkbox>
                        </Form.Item>


                        <Divider orientation="left">
                            <Title style={{margin: 0}}
                                   level={4}>{t('onboarding-flow.intolerances.title')}</Title>
                        </Divider>

                        <Form.Item
                            name={"options"}
                            initialValue={initialData.options}
                        >
                            <Checkbox.Group>
                                <Row type="flex">
                                    <Col span={24}>
                                        <Checkbox
                                            value="is_gluten_intolerant">{t('settings.meals.gluten-intolerant')}</Checkbox>
                                    </Col>
                                    <Col span={24}>
                                        <Checkbox
                                            value="is_lactose_intolerant">{t('settings.meals.lactose-intolerant')}</Checkbox>
                                    </Col>
                                    <Col span={24}>
                                        <Checkbox value="has_diabetes">{t('settings.meals.diabetes')}</Checkbox>
                                    </Col>
                                </Row>
                            </Checkbox.Group>
                        </Form.Item>

                        <Divider orientation="left">
                            <Title style={{margin: 0}}
                                   level={4}>{t('settings.meals.exclude-ingredients')}</Title>
                        </Divider>

                        <Form.Item
                            name="allergens"
                            initialValue={initialData.allergens}
                        >
                            <Checkbox.Group style={{width: '100%'}} className="allergens">
                                <Row type="flex">
                                    {allAllergens.map(allergen => (
                                        <Col span={24} md={12} key={allergen.id}>
                                            <Checkbox className="allergen"
                                                      value={allergen.id}>{allergen.title}</Checkbox>
                                        </Col>
                                    ))}
                                </Row>
                            </Checkbox.Group>
                        </Form.Item>

                        <Divider orientation="left">
                            <Title style={{margin: 0}} level={4}>{t('settings.meals.show-macros')}</Title>
                        </Divider>

                        <Form.Item
                            name="macroSetting"
                            initialValue={initialData.macroSetting}
                        >
                            <Select style={{width: 240}} onChange={onFormUpdate}>
                                <Option value={"off"}>{t('settings.macros.off')}</Option>
                                <Option value={"grams"}>{t('settings.macros.grams')}</Option>
                                <Option value={"percentage"}>{t('settings.macros.percentage')}</Option>
                            </Select>
                        </Form.Item>


                        <Divider orientation="left">
                            <Title style={{margin: 0}} level={4}>{t('personal.tdee')}</Title>
                        </Divider>


                        <Form.Item
                            name={"goal"}
                            initialValue={user.goal}
                            label={t('settings.personal.goal.title')}
                        >
                            <Select style={{width: 240}}>
                                <Option value={1}>{t('settings.personal.goal.1')}</Option>
                                <Option value={2}>{t('settings.personal.goal.2')}</Option>
                                <Option value={3}>{t('settings.personal.goal.3')}</Option>
                            </Select>
                        </Form.Item>

                        {!showManualTdee && (
                            <Form.Item
                                name={"cal_coef"}
                                initialValue={user.cal_coef}
                                label={t('settings.personal.tdee.label')}
                            >
                                <Select onChange={(i) => setCalCoef(i)} name={"cal_coef"} style={{width: "100%"}}
                                        defaultValue={user.cal_coef} key={user}>
                                    <Option
                                        value={1}>{t('settings.personal.tdee.default')} ({parseInt(originalKcal)} kcal)*</Option>
                                    <Option disabled={isNewUser() || originalKcal < 1560}
                                            value={0.90}>{t('settings.personal.tdee.minus10')}</Option>
                                    <Option disabled={isNewUser() || originalKcal < 1760}
                                            value={0.80}>{t('settings.personal.tdee.minus20')}</Option>
                                    <Option value={1.10}>{t('settings.personal.tdee.plus10')}</Option>
                                    <Option value={1.20}>{t('settings.personal.tdee.plus20')}</Option>
                                </Select>

                            </Form.Item>
                        )}

                        {!showManualTdee && (
                            <div style={{marginTop: 20}}>
                                <Button icon={<Icon component={EditOutlined}/>}
                                        onClick={() => setShowManualTdee(!showManualTdee)}>{t('settings.personal.tdee.manual')}</Button>
                            </div>
                        )}

                        {(showManualTdee) && (
                            <div style={{marginBottom: 20}}>
                                <Form.Item
                                    name={"manual_tdee"}
                                    initialValue={user.manual_tdee}
                                    label={t('settings.personal.tdee.label')}
                                >
                                    <InputNumber className="dont-update" placeholder={parseInt(dailyKcal)}
                                                 min={1}
                                                 max={9999}/>
                                </Form.Item>

                                {showManualMacros && (
                                    <Row className="user-macros" gutter={10}>
                                        <Col span={8}>
                                            <Form.Item
                                                labelCol={{span: 24}}
                                                name={"manual_fat"}
                                                initialValue={user.manual_macros.manual_fat}
                                                label={t('fat') + ' (%)'}
                                            >
                                                <InputNumber className="dont-update"
                                                             placeholder={"30%"} min={1} max={100}/>
                                            </Form.Item>
                                        </Col>

                                        <Col span={8}>
                                            <Form.Item
                                                labelCol={{span: 24}}
                                                name={"manual_carb"}
                                                initialValue={user.manual_macros.manual_carb}
                                                label={t('carbs') + ' (%)'}
                                            >
                                                <InputNumber className="dont-update"
                                                             placeholder={"50%"} min={1} max={100}/>
                                            </Form.Item>
                                        </Col>

                                        <Col span={8}>
                                            <Form.Item
                                                labelCol={{span: 24}}
                                                name={"manual_protein"}
                                                initialValue={user.manual_macros.manual_protein}
                                                label={t('protein') + ' (%)'}
                                            >
                                                <InputNumber className="dont-update"
                                                             placeholder={"20%"} min={1} max={100}/>
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                )}

                                <Row style={{gap: 8}}>
                                    <Button
                                        onClick={() => setShowManualTdee(false)}>{t('default.tdee')}</Button>
                                    <Button
                                        onClick={() => setShowManualMacros(!showManualMacros)}>{t('default.macros')}</Button>
                                </Row>
                            </div>
                        )}

                        <div>
                            {showTdeeWarning() && (
                                <p className="cal-coef-text" style={{color: "red"}}>{t('settings.tdee.warning')}</p>)}

                            <p className="cal-coef-text">{t('settings.personal.tdee.tip')}</p>

                            <p className="cal-coef-text">{t('settings.personal.tdee', {
                                daily_kcal: parseInt(dailyKcal), kcal: user.tdee
                            })}</p>
                        </div>

                        <div className="text-center">
                            <Title style={{margin: 0}}
                                   level={4}>
                                {t('settings.meals.current-count')} <CountUp duration={1}
                                                                             preserveValue={true}
                                                                             end={initialRecipeCount}/> {t('onboarding-flow.counter.title')}.
                            </Title>

                            {initialRecipeCount != currentRecipeCount && (<p className="title">
                                {t('settings.meals.new-count')} <span style={{color: "#0EB8AE"}}><CountUp
                                duration={1} preserveValue={true}
                                end={currentRecipeCount}/> {t('onboarding-flow.counter.title')}.</span>
                            </p>)}

                            <p>{t('settings.meals.save-notice')}</p>
                        </div>

                        <Row type="flex" justify="center">
                            <Button htmlType="submit" type="primary" size="large"
                                    className="main-btn">{t('common.save')}</Button>
                        </Row>
                    </Form>
                </>
            )}
        </div>
    );
};

export default withTranslation()(MealSettings);
