import React, {useEffect, useState} from 'react';
import {Layout, Row, Col, Typography, Button, Card, Carousel, Avatar, Image, Divider, message} from 'antd';
import PurchaseChallengeModal from "../components/challenges/purchase-challenge-modal";
import {
    BulbOutlined,
    GiftOutlined,
    TeamOutlined,
    UserAddOutlined, UserOutlined
} from "@ant-design/icons";
import Api from "../api";
import {useNavigate, useParams} from "react-router-dom";
import {useResizeEffect} from "../resize-effect";
import {useTranslation} from "react-i18next";

const {Title, Paragraph, Text} = Typography;
const {Content} = Layout;

const SalesPage = () => {
    const params = useParams();
    const navigate = useNavigate();
    const [showPurchase, setShowPurchase] = useState(null);
    const [challenge, setChallenge] = useState('');
    const [page, setPage] = useState(false);
    const {t} = useTranslation();
    const isMobile = useResizeEffect();

    useEffect(() => {
        Api.get('/v2/blog/page?slug=sales-page-' + params.id).then(response => {
            let pageData = response.data.data; // Fetch the page data

            // Convert fields.list to an array if it exists
            const listArray = pageData.fields.features.list
                ? Object.entries(pageData.fields.features.list).reduce((acc, [key, value]) => {
                    const [index, field] = key.split('_');
                    if (!acc[index]) acc[index] = {};
                    acc[index][field] = value;
                    return acc;
                }, []) : [];

            pageData.fields.features = listArray;

            setPage(pageData); // Set the page state with the updated data
        }).catch(err => {
            message.error('Challenge sales page not found!')
        });

        Api.get('/v3/competitions/' + params.id).then(response => {
            setChallenge(response.data.data);
        })
    }, [params.id]);

    const joinChallenge = () => {
        if (challenge.product && challenge.is_paid && !challenge.has_access) {
            setShowPurchase(challenge.product);
        } else {
            navigate('/challenges/' + params.id + '?join=true')
        }
    }

    return (
        <Layout>
            {showPurchase && (
                <PurchaseChallengeModal showPurchase={!!showPurchase} setShowPurchase={setShowPurchase}
                                        challenge={showPurchase}/>
            )}

            <Content>
                <Row justify="center" align="middle" style={{textAlign: 'center'}}>
                    <Col span={24} md={20} lg={18}>
                        {!isMobile && (
                            <img src={challenge.image} alt=""
                                 style={{width: "100%", maxWidth: 320, borderRadius: 12, marginTop: 40}}/>
                        )}

                        <Title level={1}>{challenge.name}</Title>
                        <Paragraph>
                            {challenge.summary}
                        </Paragraph>

                        {isMobile && (
                            <img src={challenge.image} alt="" style={{width: "100%", maxWidth: 320, borderRadius: 12}}/>
                        )}

                    </Col>
                </Row>

                <Divider/>

                {page && (
                    <>
                        <Row type="flex" justify="center" className="text-center sales-page-pricing">
                            <Col span={24} md={20} lg={12}>
                                <Title level={3}>{page.fields.title}</Title>
                                <Paragraph>{page.fields.description}</Paragraph>
                                <Button type="primary" size="large" onClick={joinChallenge}>{t('sales.page.btn')}</Button>
                            </Col>
                        </Row>

                        <Divider/>

                        <Row type="flex" justify="center">
                            <Col span={24} md={20} lg={18}>
                                <FeaturesList features={page.fields.features}/>
                            </Col>
                        </Row>

                        {page.content && (
                            <Row type="flex" justify="center">
                                <Col span={24} md={20} lg={18}>
                                    <div style={{marginTop: isMobile ? 40 : 60}} dangerouslySetInnerHTML={{__html: page.content}}></div>
                                </Col>
                            </Row>
                        )}

                    </>
                )}
                
                <Row justify="center" align="middle">
                    <Col span={24} md={20} lg={18} className="sales-page-join">
                        <Title level={2}
                               style={{fontSize: '2rem', marginBottom: '20px'}}>{t('sales.page.join-title')}</Title>
                        <Button type="primary" size="large" onClick={joinChallenge}>{t('sales.page.btn')}</Button>
                    </Col>
                </Row>
            </Content>
        </Layout>
    );
}

const FeaturesList = ({features}) => {
    if (!Array.isArray(features)) {
        return false;
    }

    return (
        <div>
            <h2 className="text-center" style={{fontSize: '2rem', marginBottom: '30px'}}>
                Mida väljakutse sisaldab?
            </h2>

            <Row gutter={[16, 16]} justify="center">
                {features.map((feature, index) => (
                    <Col key={index} span={24} md={12} lg={8}>
                        <Card
                            className="sales-page-feature"
                            title={<><img style={{maxWidth: 24}} src={feature.image} alt=""/> {feature.title}</>}
                            bordered={false}
                            hoverable
                        >
                            {feature.description}
                        </Card>
                    </Col>
                ))}
            </Row>
        </div>
    );
};


export default SalesPage;
