import { Avatar, Button, Popover, Tooltip } from "antd";
import React, { useState } from "react";
import { reactionList, trackEvent } from "../../helpers";
import { SmileOutlined } from "@ant-design/icons";
import { ReactionBarSelector } from "@charkour/react-reactions";
import { useSelector } from "react-redux";
import { motion } from "framer-motion";
import {use} from "i18next";

const EmojiCounter = ({ reactions = [], reactEmoji, size }) => {
    const user = useSelector(state => state.user.data);
    const [clickedReaction, setClickedReaction] = useState(null);
    const [popoverOpen, setPopoverOpen] = useState(false);

    // Group reactions by type and store user details
    const groupedReactions = reactions.reduce((acc, reaction) => {
        if (!acc[reaction.type]) {
            acc[reaction.type] = [];
        }
        acc[reaction.type].push(reaction);
        return acc;
    }, {});

    // Create an array of reaction elements with popovers showing users
    const reactionElements = Object.entries(groupedReactions).map(([type, users]) => {
        const matchingReaction = reactionList.find((item) => item.key === type);
        const userHasReacted = users.some(reaction => reaction.user_id === user.user_id); // Check if user has reacted
        console.log(userHasReacted)

        return matchingReaction ? (
            <Popover
                key={type}
                title={`${matchingReaction.label} (${users.length})`}
                content={
                    <div style={{ maxHeight: "200px", overflowY: "auto" }}>
                        {users.map(user => (
                            <div
                                key={user.user_id}
                                style={{ display: "flex", alignItems: "center", gap: 8, marginBottom: 4, fontSize: 12 }}
                            >
                                {user.display_name}
                            </div>
                        ))}
                    </div>
                }
                placement="top"
            >
                <motion.div
                    initial={{ scale: 1 }}
                    animate={{ scale: clickedReaction === type ? [1.2, 1] : 1 }}
                    transition={{ type: "spring", stiffness: 200, damping: 10 }}
                >
                    <Avatar
                        className={`reaction-emote ${!size ? "small" : ""} ${userHasReacted ? "reacted" : ""}`}
                    >
                        {matchingReaction.node}
                    </Avatar>
                </motion.div>
            </Popover>

        ) : null;
    }).filter(Boolean);


    return (
        <>
            {reactionElements.length > 0 ? (
                <div className="emoji-counter">
                    <Avatar.Group>
                        {reactionElements}
                    </Avatar.Group>

                    <Popover key="counter" title={`Kokku (${reactions.length})`}
                             content={
                                 <div style={{maxHeight: "200px", overflowY: "auto"}}>
                                     {reactions.map(user => {
                                         return (
                                             <div key={user.user_id} style={{
                                                 display: "flex",
                                                 alignItems: "center",
                                                 gap: 8,
                                                 marginBottom: 4
                                             }}>
                                                 <span>{user.display_name}</span>
                                             </div>
                                         );
                                     })}
                                 </div>
                             }
                             placement="top">
                        <span style={{fontWeight: "normal", marginLeft: "5px"}}>{reactions.length}</span>
                    </Popover>
                </div>
            ) : ''}

            <Popover
                open={popoverOpen}
                onOpenChange={setPopoverOpen}
                style={{ background: "transparent" }}
                content={
                    <div className="post-feed-reaction">
                        <ReactionBarSelector
                            reactions={reactionList}
                            iconSize={20}
                            onSelect={(value) => {
                                setClickedReaction(value);
                                reactEmoji(value);
                                trackEvent('emoji reaction', {type: value});

                                setTimeout(() => setClickedReaction(null), 500);
                                setPopoverOpen(false);
                            }}
                        />
                    </div>
                }
                title=""
                trigger="click"
            >
                <Button size={size || "small"} type="text" icon={<SmileOutlined style={{ fontSize: 14 }} />} />
            </Popover>
        </>
    );
};

export default EmojiCounter;