import axios from 'axios';
import {message} from 'antd'
import axiosRetry from 'axios-retry';

import {config, getCookie, logError, redirect, removeCookie, trackEvent} from './helpers.js';
import i18next from "i18next";

const ROOT_URL = (getCookie('custom_api') && config('feature.custom_api')) ? getCookie('custom_api') : config('api.url');
const API_TRACKING = config('api.tracking');
const OPEN_AI_API_KEY = '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';

const api = axios.create({
    baseURL: ROOT_URL
});

// Check if failed request should retry
const shouldRetry = error => {
    return axiosRetry.isNetworkError(error) || axiosRetry.isRetryableError(error) || error.code === 'ECONNABORTED' || (error.response && error.response.status === 429);
};

const isDevMode = config('dev');

axiosRetry(api, {
    retries: isDevMode ? 0 : 3,
    retryDelay: axiosRetry.exponentialDelay,
    retryCondition: shouldRetry,
    shouldResetTimeout: true
});

// Set Headers for Request
api.interceptors.request.use(config => {
    const token = getCookie('fl_access_token');

    if (token) {
        config.headers.Authorization = `Bearer ${token}`;
    }

    // Remove Authorization for login
    if (isAuthRequest(config)) {
        delete config.headers.Authorization;
    }

    config.meta = config.meta || {}
    config.meta.requestStartedAt = new Date().getTime();

    return config;
}, error => Promise.reject(error));

api.interceptors.response.use(
    (response) => response,
    (error) => {
        // Tag processed error to avoid multiple processing of single error in retry scenarios
        if (error.isProcessed) {
            return Promise.reject(error);
        }

        error.isProcessed = true;

        // call error response handling after request retried multiple times
        return errorResponseHandler(error);
    }
);

function errorResponseHandler(error) {
    if (error && error.message) {
        if (error.message.includes('timeout') || error.message.includes('Network')) {
            trackEvent("Network error", {
                error: error.message
            });

            message.error('Network error');
            return false;
        }
    }

    if (API_TRACKING != 'none') {
        let data = {
            'result': 'error',
            'raw': JSON.stringify(error)
        }
        if (error && error.response) {
            let res = error.response;
            data.url = res.config ? res.config.url : '?';
            data.method = res.config ? res.config.method : '?';
            data.status = res.status;
            data.msg = res.data != null ? res.data.message : null;
            data.requestTime = new Date().getTime() - res.config.meta.requestStartedAt;
        }
        trackEvent('API call', data);
    }

    logError(error);

    // Error codes where we should hide the message
    const errorCodes = [404, 400, 403, 422, 409];

    if (error.response && !isAuthRequest(error.response.config) && !errorCodes.includes(error.response.status) && !error.response.config.url.includes('/auth/revoke')) {
        // Unauthorized access force user to logout
        if (error.response.status === 401) {
            message.error(i18next.t('unauthorized.request'));

            // trackEvent('Unauthorized api request');

            // Unauthorized access force user to logout
            removeCookie('fl_access_token');
            removeCookie('fl_refresh_token');
            removeCookie('custom_api');

            redirect('/login');
            return error;
        }

        // Display error status and message
        message.error(error.response.status + ' ' + error.response.statusText);
    }

    return Promise.reject(error);
}

// Global error handling for all responses

function isAuthRequest(config) {
    return !!(config.url.includes('/auth/token') || config.url.includes('/auth/facebook'));
}

/*
 * Exports
 */
export default class Api {

    static get(url) {
        return api.get(url);
    }

    static post(url, data = null) {
        return api.post(url, data);
    }

    static put(url, data = null) {
        return api.put(url, data);
    }

    static delete(url, data = null) {
        return api.delete(url, {data: data});
    }

    static register(data) {
        return api.post('/register', data);
    }

    static registerFacebook(data) {
        return api.post('/register/facebook', data);
    }

    static authEmail(username, password) {
        return api.post('/auth/token', {'username': username, 'password': password});
    }

    static authFacebook(token, authToken = null) {
        return api.post('/auth/facebook', {'fb_token': token, 'auth_token': authToken});
    }

    static authGoogle(token) {
        return api.post('/auth/google', {'google_token': token});
    }

    static authRevoke() {
        return api.post('/auth/revoke');
    }

    static authApple(user, authCode) {
        return api.post('/auth/apple', {'user': user, 'code': authCode});
    }

    static newPassword(data) {
        return axios.post(ROOT_URL + 'new-password', data);
    }

    static resetPassword(email) {
        return axios.post(ROOT_URL + 'reset-password', {'email': email});
    }

    static updateUser(data) {
        return this.put('/user', data);
    }

    static inAppCall(url) {
        return axios.get(url);
    }

    static loadBanners() {
        return axios.get(config('feature.banner'));
    }

    static queryChatGpt(message) {
        return axios.post(
            'https://api.openai.com/v1/chat/completions',
            {
                model: 'gpt-3.5-turbo',
                messages: [
                    {role: 'system', content: 'You are a helpful assistant.'},
                    {role: 'user', content: message}
                ],
                temperature: 0.7,
                max_tokens: 1000
            }, {headers: {Authorization: `Bearer ${OPEN_AI_API_KEY}`, 'Content-Type': 'application/json'}}
        );
    }
}
