import React, {useState} from "react";
import {Button, Checkbox, message, Modal, Row} from 'antd';

// Redux

import {withTranslation} from 'react-i18next';
import {config, redirect, removeCookie} from "../../helpers";
import Api from "../../api";

const DeleteAccount = ({t}) => {
    const [modal, setModal] = useState(false);
    const [checked, setChecked] = useState(false);

    const onChange = (e) => {
        setChecked(e.target.checked);
    };

    const showDeleteConfirm = () => {
        Modal.confirm({
            title: t('delete.modal.title'),
            content: t('delete.modal.content'),
            okText: t('common.yes'),
            okType: 'danger',
            cancelText: t('common.no'),
            onOk() {
                Api.delete('/v1/user').then(res => {
                    removeCookie('fl_access_token');
                    removeCookie('fl_refresh_token');
                    
                    redirect(config('public_app_url'))
                }).catch(err => {
                    message.error(t('delete-account.error'));
                })
            },
            onCancel() {
                //console.log('Cancel');
            },
        });
    }

    return (
        <Row className="delete-account-block" type="flex" justify="center" align="middle">
            <Button onClick={() => setModal(true)} danger size="small">{t('delete.account.button')}</Button>

            <Modal className="generate-recipe-modal" footer={false} title={t('delete.modal.title')} visible={modal}
                   onCancel={() => setModal(false)}>
                <p>{t('delete.modal.content')}</p>

                <Row>
                    <Checkbox onChange={onChange}>{t('delete.modal.confirm')}</Checkbox>
                </Row>

                <br/>

                <Row type="flex" justify="center" align="middle" style={{gap: 8}}>
                    <Button onClick={() => setModal(false)}>{t('common.close')}</Button>
                    <Button onClick={showDeleteConfirm} disabled={!checked} type="secondary">{t('common.yes')}</Button>
                </Row>
            </Modal>
        </Row>
    );
}

export default withTranslation()(DeleteAccount);
