import {Button, Divider, Row, Select, Tag, Typography, Col} from 'antd';
import {useState} from 'react';
import {getRecipeFilters} from '../../helpers/recipe-filters.js';
import {useSelector} from "react-redux";
import {useTranslation} from "react-i18next";
import {filterObject} from "../../helpers";
import {useResizeEffect} from "../../resize-effect";
import Icon, {DownOutlined, UpOutlined, AlignLeftOutlined} from "@ant-design/icons";
import {Link} from "react-router-dom";
import SheetModal from "../blocks/sheet-modal";

const {Title} = Typography;
const {CheckableTag} = Tag;
const {Option} = Select;

const RecipeFilters = ({updateFilters, filters, modal, loading, sortOption, updateSortOption, sortOptions}) => {
    const {t} = useTranslation();
    const user = useSelector(state => state.user.data);
    const tagsData = getRecipeFilters(user);
    const [showAll, setShowAll] = useState(false);
    const [hide, setHide] = useState(false);
    const isMobile = useResizeEffect();

    const handleChange = (tag, checked) => {
        if (loading) {
            return false;
        }

        const cloneFilters = [...filters];
        const nextSelectedTags = checked ? [...cloneFilters, tag] : cloneFilters.filter((t) => t.name !== tag.name);

        updateFilters(nextSelectedTags);
    };

    const renderTags = () => {
        return (
            <>
                {tagsData.map((tag) => {
                    let isChecked = filterObject(filters, 'name', tag.name)[0];

                    return (
                        <CheckableTag
                            className={"recipe-checkable-tag " + (loading ? "loading" : "")}
                            key={tag.name}
                            checked={isChecked}
                            onChange={(checked) => handleChange(tag, checked)}
                        >
                            {t('recipes.filters.' + tag.name)}
                        </CheckableTag>
                    )
                })}
            </>
        )
    }

    return (
        <div className={"recipe-filters-modal"}>
            {!modal && !isMobile && (
                <Divider orientation="left">
                    <Title style={{margin: 0}} level={4}>{t('recipes.search.filters')} {!isMobile && (<Icon onClick={() => setHide(!hide)} style={{fontSize: 14}} component={hide ? UpOutlined : DownOutlined}/>)}</Title>
                </Divider>
            )}

            {!hide && (
                <>
                    <div className={"scroll-wrapper " + (modal ? "scroll-modal " : "")}>
                        {isMobile && (
                            <span onClick={() => setShowAll(!showAll)} className="recipe-checkable-tag" style={{marginRight: 10}}>
                                <Icon component={AlignLeftOutlined}/>
                            </span>
                        )}

                        {renderTags()}

                        {!isMobile && !modal && (
                            <Link to="/calculator">
                                <Button style={{borderRadius: 24, height: 30}}
                                        type="primary">{t('create-recipe.new')}</Button>
                            </Link>
                        )}
                    </div>

                    {(isMobile || modal) && (
                        <Row type="flex" justify="end" className={modal ? "sort-modal" : ""}>
                            <Select size={"small"} style={{minWidth: 100}} defaultValue={sortOption}
                                    onChange={updateSortOption}>
                                {sortOptions.map(data => {
                                    return <Option key={data.id} value={data.id}>{t(data.name)}</Option>
                                })}
                            </Select>

                        </Row>
                    )}
                </>
            )}

            <SheetModal open={showAll} close={() => setShowAll(false)}>
                <div>
                    <Divider orientation="left">
                        <Title style={{margin: 0}} level={4}>{t('recipes.search.filters')} {!isMobile && (
                            <Icon onClick={() => setHide(!hide)} style={{fontSize: 14}}
                                  component={hide ? UpOutlined : DownOutlined}/>)}</Title>
                    </Divider>

                    <Row>
                        {renderTags()}
                    </Row>
                </div>
            </SheetModal>
        </div>
    );
};
export default RecipeFilters;