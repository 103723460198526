import PaymentSection from "../payment/payment-section";
import React, {useState} from "react";
import Recipes from "../../routes/recipes";
import SheetModal from "../blocks/sheet-modal";
import {useTranslation} from "react-i18next";
import {Button, Row} from "antd";

const PaymentModal = ({visible, setVisible, subAccounts, showInfoBtn}) => {
    const {t} = useTranslation();

    return (
        <div>
            <SheetModal
                zindex={499}
                footer={null}
                width={1200}
                destroyOnClose={true}
                title={"Fitlap"}
                open={!!visible}
                close={() => setVisible(false)}
            >
                <PaymentSection showInfoBtn={showInfoBtn} premiumAccounts={subAccounts ? subAccounts.length : ''} premium={true} col={12}/>
            </SheetModal>
        </div>
    )
}

export default PaymentModal;