import {Link} from "react-router-dom";
import {Tag} from "antd";
import {getLevelClassname} from "../../helpers/user";
import React from "react";

const AuthorTag = ({recipe}) => {
    if (!recipe.author) {
        return false;
    }

    return (
        <Tag bordered={false} className={"author-tag ant-tag-default"}>
            <Link to={"/home-cooks/" + recipe.author.slug}>
                {recipe.author.name}
            </Link>
        </Tag>

    )
}

export default AuthorTag;