import ReactPlayer from "react-player";
import React from "react";
import {Typography} from "antd";

const {Text, Paragraph} = Typography;

const PostContentWithLinks = ({text}) => {
    // Regular expression to detect YouTube URLs
    const youtubeRegex = /(https?:\/\/)?(www\.)?(youtube\.com\/watch\?v=|youtu\.be\/|youtube\.com\/shorts\/)([a-zA-Z0-9_-]{11})/;
    const youtubeMatch = text.match(youtubeRegex);

    // Regular expression to detect image URLs
    const imageRegex = /(https?:\/\/.*\.(?:png|jpg|jpeg|gif|webp))/i;
    const imageMatch = text.match(imageRegex);

    // Extract video URL from YouTube match
    const videoUrl = youtubeMatch ? youtubeMatch[0] : null;

    // Function to render content with links
    const renderContentWithLinks = (content) => {
        if (!content) return null;

        // Split content by new lines
        const contentParts = content.split("\n");

        return contentParts.map((part, index) => {
            if (part.startsWith("http")) {
                // Render link for URLs
                return (
                    <a
                        key={index}
                        href={part}
                        target="_blank"
                        rel="noopener noreferrer"
                        style={{display: "block", textDecoration: "underline"}}
                    >
                        {part}
                    </a>
                );
            }

            // Render plain text for other lines
            return (
                <Text key={index} style={{marginBottom: 6, display: "block"}}>
                    {part}
                </Text>
            );
        });
    };

    return (
        <div>
            {renderContentWithLinks(text.replace(youtubeRegex, '').replace(imageRegex, '').trim())}

            {videoUrl && (
                <div className="video-container" style={{marginBottom: 20}}>
                    <ReactPlayer
                        url={videoUrl}
                        width="100%"
                        height="300px"
                    />
                </div>
            )}

            {imageMatch && (
                <div className="image-container" style={{marginBottom: 20}}>
                    <img src={imageMatch[0]} alt="Post" style={{maxWidth: '100%', height: 'auto'}}/>
                </div>
            )}
        </div>
    );
};

export default PostContentWithLinks