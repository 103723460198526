import React, {useEffect, useState} from "react";
import {
    Badge,
    Col,
    Divider,
    Progress,
    Row,
    Typography,
    Card,
    Steps,
    message,
    Space,
    Tooltip, notification, Tag, Avatar, Button, Popover
} from "antd";
import api from "../../api";
import Icon, {
    CheckCircleOutlined,
    TrophyOutlined,
    LockOutlined,
    TrophyTwoTone,
    StarOutlined,
    StarFilled, StarTwoTone, InfoCircleOutlined, SyncOutlined
} from "@ant-design/icons";
import {round} from "lodash";
import BingoCardModal from "./bingo-card-modal";
import {useTranslation} from "react-i18next";
import {useResizeEffect} from "../../resize-effect";
import moment from "moment";
import {Link} from "react-router-dom";
import BingoChart from "./bingo-chart";
import Api from "../../api";

const {Title, Text} = Typography;

const BingoChallenge = ({competition}) => {
    const {t} = useTranslation();
    const [modal, setModal] = useState(false);
    const [challenge, setChallenge] = useState([]);
    const [level, setLevel] = useState(0);
    const isMobile = useResizeEffect();
    const [notificationApi, contextHolder] = notification.useNotification();
    const startDate = competition.begin_date ? moment(competition.begin_date) : moment("2025-01-01");
    const endDate = competition.end_date ? moment(competition.end_date) : moment("2025-02-28");
    const today = moment();
    const totalDays = endDate.diff(startDate, "days");
    const daysPassed = today.diff(startDate, "days");
    const progressPercent = (daysPassed / totalDays) * 100;
    const [hasJoinedCompetition, setHasJoined] = useState(competition.participating);
    const openNotification = (value) => {
        notificationApi.info({
            icon: <TrophyTwoTone twoToneColor={"#0EB8AE"}/>,
            className: "unlock-notification",
            message: `Uus tase avatud!`,
            description: <div>Suurepärane! Tegid ära kõik ülesanded ja avasid <strong>Tase {value}!</strong></div>,
            placement: "top",
        });
    };

    useEffect(() => {
        getChallenge();
    }, []);

    useEffect(() => {
        if (challenge) {
            // Find the latest unlocked level
            const lastUnlockedLevel = challenge.findIndex(level => level.completed < level.items.length);

            setLevel(lastUnlockedLevel !== -1 ? lastUnlockedLevel : challenge.length - 1); // Fallback to last level if all are completed
        }
    }, [challenge]);

    const getChallenge = () => {
        api.get('/v3/bingo-challenge/' + competition.bingo_id).then(res => {
            setChallenge(res.data.data.categories.data);
        });
    };

    const completeChallengeCard = (item) => {
        api.put(`/v3/bingo-challenge/${competition.id}/challenge/${item.id}`).then(res => {

            api.get('/v3/bingo-challenge/' + competition.bingo_id).then(res => {
                const updatedChallenge = res.data.data.categories.data;

                setChallenge(res.data.data.categories.data);

                // Find the current level and check if all items are completed
                const currentLevelIndex = updatedChallenge.findIndex(level =>
                    level.items.some(challengeItem => challengeItem.id === item.id)
                );

                if (currentLevelIndex !== -1) {
                    const currentLevel = updatedChallenge[currentLevelIndex];
                    const isLevelCompleted = currentLevel.completed === currentLevel.items.length;

                    // If current level is completed, check if next level is now unlocked
                    if (isLevelCompleted && currentLevelIndex + 1 < updatedChallenge.length) {
                        openNotification(currentLevelIndex + 2);
                    } else {
                        if (!item.completed.status) {
                            message.open({
                                content: (
                                    <div>
                                        +1 <StarFilled style={{color: "orange"}}/> earned!
                                    </div>
                                ),
                                icon: null, // This removes the default success checkmark icon
                            });
                        }
                    }
                } else {
                    if (!item.completed.status) {
                        message.open({
                            content: (
                                <div>
                                    +1 <StarFilled style={{color: "orange"}}/> earned!
                                </div>
                            ),
                            icon: null, // This removes the default success checkmark icon
                        });
                    }
                }

                setModal(false);
            });
        });
    };

    const joinChallenge = () => {
        // Remove user from competition
        if (hasJoinedCompetition) {
            Api.delete('/v2/user/competitions/' + competition.id).then(res => {
                message.info('Lahkusid väljakutsest')

                setHasJoined(false);
            });
        } else {
            // Add user to competition
            Api.post('/v2/user/competitions/' + competition.id).then(res => {
                setHasJoined(true);

                message.success(t('challenge.joined'))
            });
        }
    }

    return (
        <Row gutter={[40, 40]} type="flex" justify="center">
            {contextHolder}

            <BingoCardModal setModal={setModal} item={modal} completeChallengeCard={completeChallengeCard}/>

            <Col span={24} lg={16}>
                <Card cover={<Avatar
                    style={{width: "100%"}}
                    shape="square"
                    size={isMobile ? 100 : 160}
                    src={competition.image}
                />} hoverable style={{marginBottom: 20}}>
                    <Row gutter={[16, 16]} align="middle">
                        <Col flex="auto">
                            <Space direction="vertical" style={{width: "100%"}}>
                                <Title level={4} style={{margin: 0}}><strong>{competition.name}</strong></Title>
                                <Text
                                    type="secondary">{startDate.format("D. MMM, YYYY")} - {endDate.format("D. MMM, YYYY")}</Text>

                                <Row style={{gap: 8}}>
                                    <Button type={hasJoinedCompetition ? "default" : "primary"}
                                            onClick={joinChallenge}>{hasJoinedCompetition ? t('competition.quit') : t('competition.join')}</Button>

                                    <Link to={"/sales-page/" + competition.id}>
                                        <Button type="default" icon={<InfoCircleOutlined/>}>{t('challenge.info')}</Button>
                                    </Link>
                                </Row>
                            </Space>
                        </Col>
                    </Row>
                </Card>

                {isMobile && (
                    <EarnedStars level={level} challenge={challenge} isMobile={true} setLevel={setLevel}/>
                )}


                <div className="fitlap-challenge">
                    <Divider orientation="left">
                        <Title style={{margin: 0}} level={4}>Ülesanded</Title>
                    </Divider>

                    <Space direction="vertical" style={{width: "100%"}}>
                        <Row type="flex" gutter={[20, 20]}>
                            {challenge[level]?.items?.map(item => (
                                <Col key={item.id} span={12} md={8} lg={8}>
                                    <div onClick={() => setModal(item)}
                                         className={`challenge-card ${item.completed.status ? "completed" : ""}`}>
                                        <strong>{item.name}</strong>

                                        {!item.completed.status ? (
                                            <Tag style={{position: "absolute", right: 10, top: 10}}
                                                 className="ant-tag-default">
                                                <>
                                                    +1 <StarFilled style={{color: "orange"}}/>
                                                </>
                                            </Tag>

                                        ) : (
                                            <div></div>
                                        )}
                                    </div>
                                </Col>
                            ))}
                        </Row>
                    </Space>
                </div>
            </Col>

            <Col span={24} lg={8}>
                <Row gutter={[20, 20]} type="flex" justify="center">
                    {!isMobile && (
                        <Col span={24}>
                            <EarnedStars level={level} challenge={challenge} isMobile={false} setLevel={setLevel}/>
                        </Col>
                    )}

                    <Col span={24}>
                        <BingoChart bingo={challenge}/>
                    </Col>

                </Row>
            </Col>
        </Row>
    );
};

const EarnedStars = ({level, challenge, isMobile, setLevel}) => {
    const {t} = useTranslation();

    const isLevelUnlocked = (index) => {
        if (index === 0) return true; // Level 1 is always unlocked
        return challenge[index - 1]?.completed === challenge[index - 1]?.items?.length;
    };

    const handleStepChange = (newLevel) => {
        if (isLevelUnlocked(newLevel)) {
            setLevel(newLevel);
        }
    };

    const getProgressPercent = () => {
        if (challenge.length === 0 || !challenge[level]) return 0;

        const {completed, items} = challenge[level];

        return items.length > 0 ? round((completed / items.length) * 100) : 0;
    };
    return (
        <Card>
            <Divider orientation="left">
                <Title style={{margin: 0}} level={4}>Väljakutse tase</Title>
            </Divider>

            <Steps
                responsive={false}
                current={level}
                onChange={handleStepChange}
                items={challenge.map((ch, index) => ({
                    title: !isMobile ? `Tase ${index + 1}` : index + 1,
                    status: index === level ? 'process' : index < level ? 'finish' : 'wait',
                    icon: isLevelUnlocked(index) ? (
                        <TrophyOutlined/>
                    ) : (
                        <Tooltip title="Complete all previous tasks to unlock">
                            <LockOutlined/>
                        </Tooltip>
                    ),
                }))}
            />

            <br/>
            <Text type="secondary">
                {t('stars.earned')} {challenge[level]?.completed || 0} / {challenge[level]?.items?.length || 0} <StarOutlined/>
            </Text>

            <Progress
                percentPosition={{type: 'hidden'}}
                strokeColor={{'0%': '#ee7752', '50%': '#e73c7e'}}
                percent={getProgressPercent()}
                status="active"
            />
        </Card>
    )
}

export default BingoChallenge;
