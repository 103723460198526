import PersonalDetails from "./personal-details";
import {useTranslation} from "react-i18next";
import MealSettings from "./meal-settings";
import {Col, Row, Tabs} from "antd";
import {useState} from "react";

const SettingsPage = ({defaultTab}) => {
    const {t} = useTranslation();
    const [activeTab, setActiveTab] = useState(defaultTab ? defaultTab : 'personal');

    const items = [
        {
            key: 'personal',
            label: t('settings.personal.title'),
            children: <PersonalDetails/>,
        },
        {
            key: 'meals',
            label: t('menu.settings.meals'),
            children: <MealSettings/>,
        },
    ];

    return (

        <Row type="flex" justify="center">
            <Col span={24} md={24} xxl={14}>
                <Tabs defaultActiveKey={activeTab} items={items} onChange={setActiveTab}/>
            </Col>
        </Row>
    )
}

export default SettingsPage;