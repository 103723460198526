import React from "react";
import {Button, Form, Input, message, Modal} from "antd";
import Api from "../../api.js";
import {withTranslation} from "react-i18next";

const ChangePasswordModal = ({t, visible, close}) => {
    const handleSubmit = values => {
        Api.post('/v1/user/change-password', values).then(res => {
            message.success('Password has been changed!');

            close();
        }).catch(err => {
            if (err.response.data.code == 1002) {
                message.error(t('change-password.error.limit'));
            }

            if (err.response.data.code == 1001) {
                message.error(t('change-password.error.incorrect'));
            }
        });
    };

    return (
        <Modal
            className="change-password-modal"
            title={t('change-password.title')}
            open={visible}
            onOk={close}
            onCancel={close}
            footer={false}
        >
            <br/>
            <Form onFinish={handleSubmit}
                  labelCol={{span: 8}}
                  wrapperCol={{span: 14}}
            >
                <Form.Item
                    name="old_password"
                    rules={
                        [
                            {
                                required: true,
                                message: t('change-password.error.required'),
                            }
                        ]
                    }
                    label={t('change-password.old-password')}>
                    <Input type="password" data-lpignore="true"/>
                </Form.Item>

                <Form.Item
                    name="password"
                    label={t('change-password.new-password')}
                    rules={[
                        {
                            required: true,
                            message: t('change-password.error.required'),
                        },
                        {
                            min: 8,
                            max: 32,
                            message: t('change-password.error.length')
                        }
                    ]}
                    hasFeedback
                >
                    <Input.Password/>
                </Form.Item>

                <Form.Item
                    name="password_confirmation"
                    label={t('change-password.repeat-password')}
                    dependencies={['password']}
                    hasFeedback
                    rules={[
                        {
                            required: true,
                            message: t('change-password.error.confirm'),
                        },
                        ({getFieldValue}) => ({
                            validator(_, value) {
                                if (!value || getFieldValue('password') === value) {
                                    return Promise.resolve();
                                }
                                return Promise.reject(new Error('The two passwords that you entered do not match!'));
                            },
                        }),
                    ]}
                >
                    <Input.Password/>
                </Form.Item>

                <Form.Item label={" "} colon={false}>
                        <Button onClick={close} type="link">{t('common.cancel')}</Button>
                        <Button className="main-btn" size="large" type="primary"
                                htmlType="submit">{t('common.save')}</Button>
                </Form.Item>
            </Form>
        </Modal>
    );
}

export default withTranslation()(ChangePasswordModal);