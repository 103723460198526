import {App, Button, Col, message, Modal, Row, Space, Typography} from "antd";
import moment from "moment";
import {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useNavigate, useParams} from "react-router-dom";
import Api from "../../api";
import {dateDifference, getAmplitudeMealName, getAmplitudeSource, getMealName, round, trackEvent} from "../../helpers";
import {getUserMenu, setMenuDate} from "../../actions";
import Icon, {LeftOutlined, RightOutlined} from "@ant-design/icons";
import {useTranslation} from "react-i18next";
import {recipeIsComplete} from '../../helpers/meal-plan-helper'
import SheetModal from "../blocks/sheet-modal";

const mealTypes = [1, 2, 4, 8];
const {Title} = Typography;

const AddToMenuModal = ({id, close, visible, callback, recipeData, copy, isAllowedDefault, isSnack, aiRecipeCallback}) => {
    const params = useParams();
    const {t} = useTranslation();
    const navigate = useNavigate();
    const today = moment().format("YYYY-MM-DD");
    const dispatch = useDispatch();
    const [date, setDate] = useState(today);
    const [addedDate, setAddedDate] = useState(false);
    const [addedToDiary, setAddedToDiary] = useState(false);
    const { message } = App.useApp();
    const [loading, setLoading] = useState(false);
    const [isAllowed, setIsAllowed] = useState(true);

    useEffect(() => {
        if (recipeData && recipeData.id) {
            Api.get('/v2/recipes/' + recipeData.id).then((res) => {
                setIsAllowed(!res.data.data.ingredients_energy ? true : res.data.data.is_allowed)
            });
        } else {
            // Cannot validate by recipe id check by default value
            setIsAllowed(isAllowedDefault);
        }
    }, [recipeData])

    const copyMeal = (type) => {
        const data = {
            date: date,
            meal_type: type
        };

        setLoading(type);

        // Update meal in meal plan
        Api.post("/v2/meals/" + params.id + "/copy", data).then(res => {
            const meal = res.data.data;

            trackEvent('copied meal', {
                recipe_name: meal.recipe.original_name.toLowerCase(),
                meal_type: getAmplitudeMealName(meal.meal_type)
            });

            message.success(t('meal-plan.copy-meal.copied'));

            // If active date is today reload all meals in menu
            if (dateDifference(date) === 0) {
                dispatch(getUserMenu(date));
            }

            setLoading(false);
        }).catch(err => {
            message.error(t('meal-plan.copy-meal.error'));
            setLoading(false);
        });
    };

    const addToMenu = (type) => {
        setAddedToDiary(false);

        if (copy) {
            copyMeal(type);

            return;
        }

        const data = {
            recipe_id: id ? id : recipeData,
            date: date,
            meal_type: type,
            substitutes: []
        };

        // If recipe has substitute ingredients
        if (recipeData && recipeData.ingredients) {
            recipeData.ingredients.map(ingredient => {
                if (ingredient.id !== ingredient.original_id) {
                    data.substitutes.push({
                        ingredient_id: ingredient.original_id,
                        substitute_id: ingredient.id
                    })
                }
            })
        }

        // Update meal in meal plan
        Api.post("/v2/meals/recipe/change-by-date", data).then(res => {
            trackEvent('added recipe to menu', {
                recipe_name: res.data.data.recipe.original_name.toLowerCase(),
                meal_type: getAmplitudeMealName(res.data.data.meal_type),
                source: getAmplitudeSource()
            })

            Api.post('/v2/user/recipes/history', {recipe_id: id ? id : recipeData});

            message.success(t('recipes.added-to-menu'));

            setAddedDate(date);

            if (callback) {
                callback(res.data.data);
            }
        }).catch(err => {
            message.error('error occured');
        });

    };

    const changeDate = (days) => {
        setDate(moment().add(days, "days").format("YYYY-MM-DD"))
    };

    const goToMenu = (type) => {
        dispatch(setMenuDate(addedDate));

        if (aiRecipeCallback) {
            aiRecipeCallback()
        }

        navigate(type);
    }

    const addToDiary = () => {
        const data = {
            date: date,
            recipe_id: id,
            substitutes: []
        };

        // If recipe has substitute ingredients
        if (recipeData && recipeData.ingredients) {
            recipeData.ingredients.map(ingredient => {
                if (ingredient.id !== ingredient.original_id) {
                    data.substitutes.push({
                        ingredient_id: ingredient.original_id,
                        substitute_id: ingredient.id
                    })
                }
            })
        }

        Api.post('/v2/diary/recipe', data).then(res => {
            setAddedToDiary(true);

            setAddedDate(date);

            message.success(t('recipes.added-to-diary'));
        }).catch(err => {
            message.error('error occured');
        });
    }

    return (
        <SheetModal
            destroyOnClose={true}
            className="add-to-menu-modal"
            title={t('recipes.add-to-menu')}
            open={!!visible}
            close={close}
            footer={false}
            width={400}
        >
            <Row type="flex" justify="center">
                <Col span={22} md={16}>
                    <br/>
                    <Row align="middle" type="flex" justify="space-between">
                        <Col>
                                {(dateDifference(date) > 0) && (
                                    <span className="date-arrow" onClick={() => {
                                        changeDate(dateDifference(date) - 1)
                                    }}>
                                <Icon component={LeftOutlined}/>
                            </span>
                            )}
                        </Col>

                        <Col>
                            <Title level={4} style={{margin: 0}}>{moment(date).format("dddd, Do MMM")}</Title>
                        </Col>

                        <Col>
                        {(dateDifference(date) < 10) && (
                            <span className="date-arrow" onClick={() => {
                                changeDate(dateDifference(date) + 1)
                            }}><Icon component={RightOutlined}/></span>
                        )}
                        </Col>
                    </Row>
                </Col>
            </Row>

            <br/>

            <Row type="flex" justify="center">
                <Col span={16}>
                    <Space direction="vertical" style={{width: "100%"}}>

                    {
                        mealTypes.map(id => {
                            let disabled = !isAllowed;

                            return (
                                <Button disabled={loading || disabled}  loading={loading === id} key={id} block onClick={() => addToMenu(id)} size="large">{getMealName(id)}</Button>
                            )
                        })
                    }

                        <Button disabled={loading} block onClick={addToDiary} size="large">{t('add.diary')}</Button>
                    </Space>
                </Col>
            </Row>

            {addedDate && (
                <Row type="flex" justify="center" style={{marginTop: 20}}>
                    <Col span={16}>
                        {addedToDiary ? (

                            <Button block type="primary" onClick={() => goToMenu('/diary')}
                                    size="large">{t('recipes.go-to-diary')}</Button>
                        ) : (
                            <Button block type="primary" onClick={() => goToMenu('/meal-plan')}
                                    size="large">{t('recipes.go-to-meal-plan')}</Button>

                        )}
                    </Col>
                </Row>
            )}
        </SheetModal>
    )
}

export default AddToMenuModal;