import React from "react";
import {Checkbox, Col, Divider, message, Row, Typography} from 'antd';
import {isDomain, isMobile, removeCookie, setCookie} from "../../helpers";

// Redux
import {useDispatch, useSelector} from "react-redux";
import {updateAppNavMenu} from "../../actions";

import {withTranslation} from 'react-i18next';
import Api from "../../api";

const {Title} = Typography;

const AppSettings = ({t}) => {
    const dispatch = useDispatch();
    const app_menu = useSelector(state => state.app.app_menu);

    const changeAppNav = (values, e) => {
        if (app_menu.length < 9) {
            if (values.length > 8) {
                return message.error(t('nav-limit.error'));
            }
        }

        // setCookie('custom_app_nav', JSON.stringify(values), 999);

        Api.put('/v1/user', {navigation_menu: values}).then((res) => {
            // Remove old cookie based system
            removeCookie('custom_app_nav');
        })

        dispatch(updateAppNavMenu(values));
    }

    if (!isMobile() || isDomain('com')) {
        return false;
    }

    return (
        <Row type="flex" justify="center" className="app-settings-wrapper">
            <Col span={24} md={18}>
                <Divider orientation="left">
                    <Title style={{margin: 0}} level={4}>{t('app.settings')}</Title>
                </Divider>


                <Checkbox.Group value={app_menu} defaultValue={app_menu} onChange={changeAppNav}
                                options={[
                                    {label: t('header.meal-plan'), value: "/meal-plan"},
                                    {label: t('header.diary'), value: "/diary"},
                                    {label: t('header.cookbook'), value: "/cookbook"},
                                    {label: t('header.goals'), value: "/goals"},
                                    {label: t('header.shopping-list'), value: "/shopping-list"},
                                    {label: t('header.training-diary'), value: "/training-diary"},
                                    {label: t('header.training'), value: "/training"},
                                    {label: t('header.profile'), value: "/profile"},
                                    {label: t('header.blog'), value: "/blog"},
                                    {label: t('header.activity'), value: "/goals?tab=activity"},
                                    {label: t('menu.affiliate'), value: "/settings/affiliate"},
                                    {label: t('header.generate-recipe'), value: "/calculator"},
                                ]}/>
            </Col>
        </Row>
    );
}

export default withTranslation()(AppSettings);