import {Alert} from "antd";
import {getCookie, setCookie} from "../../helpers";
import React from "react";
import {useTranslation} from "react-i18next";
import Icon from "@ant-design/icons";
import {SwapIcon} from "../../icons";

const SwapIngredientTip = () => {
    const {t} = useTranslation();

    const close = () => {
        setCookie('meal_tip_closable', true, 999)
    }

    if (getCookie('meal_tip_closable')) {
        return false;
    }

    return (
            <Alert icon={<Icon className="custom-icon" component={SwapIcon}/>} closable onClose={close} style={{marginTop: 12}} message={t('meal.swap.tip')}
                   type="info" showIcon/>
    )
}

export default SwapIngredientTip;