import {Button, Col, Divider, Empty, FloatButton, Modal, Row, Select, Typography} from "antd";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {useEffect, useState} from "react";
import InfiniteScroll from 'react-infinite-scroller';
import RecipeCard from "../components/meal-plan/recipe-card.jsx"
import {filterObject, getAmplitudeSource, trackEvent} from "../helpers";
import {defaultSortOptions, publicSortOptions} from "../../config/recipe-sort-options.js";
import qs from "qs";
import Api from "../api";
import RecipeCardSkeleton from "../components/meal-plan/recipe-card-skeleton";
import RecipeFilters from "../components/recipes/recipe-filters";
import {useResizeEffect} from "../resize-effect";
import NewRecipes from "../components/recipes/new-recipes";
import AddToMenuModal from "../components/modals/add-to-menu-modal";
import {Link, useLocation, useNavigate} from "react-router-dom";
import SearchRecipes from "../components/recipes/search-recipes";
import SingleRecipeModal from "../components/modals/single-recipe-modal";
import {updateFavorites} from "../helpers/meal-plan-helper";
import {PlusOutlined} from "@ant-design/icons";
import CookbookTip from "../components/blocks/cookbook-tip";

const {confirm} = Modal;
const {Title} = Typography;
const {Option} = Select;

const Recipes = ({chooseRecipe, modal}) => {
    const {t} = useTranslation();
    const location = useLocation();
    const navigate = useNavigate();
    const scrollable = modal;
    const dispatch = useDispatch();
    const defaultRecipeId = qs.parse(location.search)['?id'];
    const defaultFilter = qs.parse(location.search)['?name'];
    const keyword = useSelector(state => state.recipes.keyword);
    const [loading, setLoading] = useState(false);
    const [recipes, setRecipes] = useState([]);
    const [meta, setMeta] = useState('');
    const [sortOptions, setSortOptions] = useState(defaultSortOptions);
    const [sortOption, setSortOption] = useState(defaultSortOptions[0].id);
    const [filters, setFilters] = useState((defaultFilter === 'all' || !defaultFilter) ? [] : [{name: defaultFilter}]);
    const [perPage, setPerPage] = useState(12);
    const [showPopular, setShowPopular] = useState(true);
    const [showDisliked, setShowDisliked] = useState(false);
    const [add_to_menu_modal, setAddToMenu] = useState(false);
    const [single_recipe_modal, setSingleRecipeModal] = useState(false);
    const isMobile = useResizeEffect();

    useEffect(() => {
        if (!modal) {
            window.scrollTo(0, 0);
        }

        // Open single recipe modal if default recipe id exists in url
        if (defaultRecipeId) {
            setSingleRecipeModal({id: parseInt(defaultRecipeId)});
        }
    }, []);

    useEffect(() => {
        if (defaultFilter) {
            setFilters(defaultFilter === 'all' ? [] : [{name: defaultFilter}]);
        }
    }, [location]);

    useEffect(() => {
        getRecipes();
    }, [keyword, sortOption, filters]);

    // Change sort options based on selected filter
    useEffect(() => {
        let isUserPublic = filters.some(e => e.name === 'is_user_public');

        if (isUserPublic) {
            setSortOptions(publicSortOptions);
        }
    }, [filters]);


    const changeFavorites = (recipe) => {
        updateFavorites(recipe, recipes, data => {
            setRecipes(data);
            setMeta(meta);
        })
    };

    const getRecipes = (loadMore) => {
        if (loading || (loadMore && !meta)) {
            return false;
        }

        if (!loadMore && document.getElementById('scroll-wrapper')) {
            document.getElementById('scroll-wrapper').scrollTo(0, 0);
        }

        setLoading(true);

        let keywords = '';
        let sortQuery = '';
        let filterQuery = '';
        let page = loadMore ? meta.current_page + 1 : 1;
        let dislikedFilter = filters.some(e => (e.name === 'is_disliked'));
        let isCustomFilter = filters.some(e => (e.name === 'is_custom'));
        let isNotRandom = keyword || filters.some(e => (e.name === 'is_user_public') || (e.name === 'is_favorite'));

        // Reset recipes to 0
        if (!loadMore) {
            setRecipes([]);
        }

        // Check for keywords
        if (keyword) {
            keywords = '&keywords=' + keyword
        }

        let trackFilters = [];

        // Check for filters
        if (filters.length > 0) {
            filters.map(filter => {
                let filterValue = 1;
                let name = filter.name;

                trackFilters.push(filter.name);

                if (filter.name.includes('meal_type')) {
                    filterValue = filter.name.slice(-1);
                    name = 'meal_type';
                }

                if (filter.name.includes('max_ingredients')) {
                    filterValue = 4;
                }

                filterQuery += filter.category ? '&categories[]=' + filter.category : '&' + name + '=' + filterValue;
            })
        }

        // Always add is_user_public to favorite author query...
        if (filterQuery.includes('is_favorite_author')) {
            filterQuery += '&is_user_public=1';
        }

        // Check for sorting
        if (sortOption) {
            let value = sortOption;

            // Check if sortOption is set default to random
            if (sortOption === defaultSortOptions[0].id) {
                if (isNotRandom) {
                    value = 8;
                }

                if (isCustomFilter) {
                    value = 1;
                }
            }

            let sort = filterObject(sortOptions, 'id', value);

            if (sort.length > 0) {
                sortQuery = '&sort=' + sort[0].value + '&order=' + sort[0].type;
            }
        }

        // Show popular only if no filters, keywords added
        if (filters.length > 0 || keyword) {
            setShowPopular(false);
        } else {
            if (!loadMore) {
                setShowPopular(true);
            }
        }

        if (dislikedFilter) {
            getDislikedRecipes(loadMore, page);
            return false;
        } else {
            setShowDisliked(false);
        }

        Api.get('/v2/recipes?per_page=' + perPage + '&page=' + page + keywords + filterQuery + sortQuery).then(response => {
            if (keyword || trackFilters.length > 0) {
                trackEvent('searched recipe', {
                    search_term: keyword,
                    sort_option: sortOption,
                    search_category: `${trackFilters}`,
                    source: getAmplitudeSource()
                });
            }

            setMeta(response.data.meta);
            setRecipes(loadMore ? [...recipes, ...response.data.data] : response.data.data);
            setLoading(false);
        });
    }

    const getParentScroll = () => {
        if (document.getElementById('scroll-wrapper')) {
            return document.getElementById('scroll-wrapper');
        }

        return null;
    }

    const onScroll = (e) => {
        if (scrollable && showPopular) {
            setShowPopular(false);
        }
    };

    const getDislikedRecipes = (loadMore, page) => {
        setShowDisliked(true);

        Api.get('/v1/user/disliked-recipes?page=' + page).then(res => {
            setRecipes(loadMore ? [...recipes, ...res.data.data] : res.data.data);
            setLoading(false);
            setMeta(res.data.meta);
        });
    }

    const clickAddToMenu = (e) => {
        if (chooseRecipe) {
            setSingleRecipeModal(false);
        }

        return chooseRecipe ? chooseRecipe(e) : setAddToMenu(e);
    }

    const updateSortOption = (id) => {
        setSortOption(id);
    };

    return (
        <div id="recipes">
            <Row type="flex" justify="center">
                <Col span={24} md={24} lg={20}>
                    {(isMobile || modal) && (
                        <Row type="flex" justify="center">
                            <SearchRecipes modal={modal}/>
                        </Row>
                    )}

                    <CookbookTip/>

                    <SingleRecipeModal recipes={recipes} setRecipes={setRecipes} openAddToMenu={clickAddToMenu}
                                       data={single_recipe_modal} modal={modal}
                                       close={() => setSingleRecipeModal(false)}
                                       visible={!!single_recipe_modal}/>

                    <AddToMenuModal recipeData={add_to_menu_modal} id={add_to_menu_modal.id} visible={add_to_menu_modal}
                                    close={() => setAddToMenu(false)} isSnack={add_to_menu_modal.is_snack}/>

                    <RecipeFilters loading={loading} modal={modal} filters={filters} updateFilters={setFilters} sortOptions={sortOptions} sortOption={sortOption} updateSortOption={updateSortOption}/>

                    {!isMobile && !modal && (
                        <Row type="flex" justify="end">
                            <Select className={isMobile ? "text-right" : ""}
                                    size={isMobile ? "small" : "normal"} style={{minWidth: 150}}
                                    defaultValue={sortOption}
                                    onChange={updateSortOption}>
                                {sortOptions.map(data => {
                                    return <Option key={data.id} value={data.id}>{t(data.name)}</Option>
                                })}
                            </Select>
                        </Row>
                    )}


                    {filters.length === 0 && !keyword && !modal && (
                        <>
                            <NewRecipes openRecipe={setSingleRecipeModal}/>
                        </>
                    )}

                    {!modal && (
                        <Divider orientation="left">
                            <Title style={{margin: 0}}
                                   level={4}>{t('header.recipes')} {meta && recipes.length > 0 && `(${meta.total})`}</Title>
                        </Divider>
                    )}

                    <div onScroll={onScroll} id={scrollable ? "scroll-wrapper" : ""}>
                        <InfiniteScroll
                            initialLoad={false}
                            pageStart={0}
                            loadMore={() => getRecipes(true)}
                            hasMore={meta ? meta.current_page < meta.last_page : false}
                            useWindow={document.getElementById('scroll-wrapper') ? false : true}
                            getScrollParent={() => getParentScroll()}
                        >
                            <Row type="flex" gutter={[20, 20]}>
                                <>
                                    {recipes.map(recipe => {
                                        return (
                                            <Col key={recipe.id} span={12} md={8} xxl={6}>
                                                <RecipeCard edit={recipe.owner} changeFavorites={changeFavorites}
                                                            isModal={scrollable}
                                                            openRecipeModal={setSingleRecipeModal}
                                                            setAddToMenu={!showDisliked ? clickAddToMenu : false} recipe={recipe}/>
                                            </Col>
                                        )
                                    })}
                                </>
                            </Row>
                        </InfiniteScroll>

                        {loading && (
                            <Row gutter={20} className="recipe-skeleton-container">
                                {
                                    [1, 2, 3, 4, 5, 6, 7, 8].map(index => {
                                        return (
                                            <RecipeCardSkeleton key={index}/>
                                        )
                                    })
                                }
                            </Row>
                        )}

                        {!loading && recipes.length === 0 && (
                            <Empty/>
                        )}
                    </div>

                </Col>
            </Row>

            {filters.length === 0 && !modal && (
                <Row type="flex" justify="center" style={{marginTop: 20}}>
                    <Link to={"/cookbook?name=is_user_public"}>
                        <Button size="large" type="default">{t('show.user-recipes')}</Button>
                    </Link>
                </Row>
            )}

            {isMobile && (
                <FloatButton style={{zIndex: 499}} icon={<PlusOutlined/>} onClick={() => navigate('/calculator')}/>
            )}
        </div>
    );
}

export default Recipes;