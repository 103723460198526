import {useTranslation} from "react-i18next";
import {App, Button, Card, Modal, Radio, Tag, Tooltip} from "antd";
import Icon, {CloseOutlined, DeleteOutlined, EditOutlined, PlusOutlined} from "@ant-design/icons";
import {getMealName, onImageError} from "../../helpers";
import {AppleIcon, EatenAppleIcon, HeartEmptyIcon, HeartIcon, SearchIcon, SwapIcon} from "../../icons"
import {changeEaten, changeRecipe} from "../../helpers/meal-plan-helper";
import {Link, useNavigate} from "react-router-dom";
import RecipeTags from "./recipe-tags";
import React, {useEffect, useState} from "react";
import {motion} from "framer-motion";
import {Text} from "recharts";
import {useResizeEffect} from "../../resize-effect";
import Api from "../../api";
import {cheatMealAvailableSuccess, getUserEatenKcal, getUserRecipes} from "../../actions";
import {useDispatch, useSelector} from "react-redux";
import placeholder from "../../../assets/images/placeholder.png";
import i18next from "i18next";
import AuthorTag from "../recipes/author-tag";

const {Meta} = Card;
const {confirm} = Modal;

const RecipeCardDefault = ({
                               isMeal,
                               meal,
                               setSearchModal,
                               updateMenu,
                               recipe,
                               setAddToMenu,
                               openRecipeModal,
                               changeFavorites,
                               hideEaten,
                               edit,
                               disabled,
                                isModal
                           }) => {
    const {t} = useTranslation();
    const navigate = useNavigate();
    const recipeData = recipe ? recipe : meal.recipe;
    const isMobile = useResizeEffect();
    const dispatch = useDispatch();
    const date = useSelector(state => state.date);
    const [loading, setLoading] = useState(false);
    const [isLiked, setIsLiked] = useState(recipeData.is_favorite);
    const isCheatMeal = (isMeal && meal.is_cheat_meal);
    const {message} = App.useApp();

    const updateEaten = () => {
        if (loading) {
            return false;
        }

        setLoading(true);


        changeEaten(meal, (data) => {
            setLoading(false);
            updateMenu(data);

            if (!meal.has_eaten) {
                message.success(i18next.t('meal-plan.eaten-success', {meal: getMealName(meal.meal_type)}));
            }

            // Reload diary with new meal ingredients
            dispatch(getUserEatenKcal());
        });
    }

    const openRecipe = () => {
        if (openRecipeModal) {
            openRecipeModal(recipe);
        }

        if (isMeal) {
            navigate('/meal/' + meal.id)
        }
    }

    const editRecipe = () => {
        navigate('/calculator/' + recipe.id)
    }

    const deleteRecipe = () => {
        deleteRecipeWarning(() => {
            Api.delete('/v2/user/recipes/' + recipeData.id).then(() => {
                message.success(t('recipes.user.success'));

                dispatch(getUserRecipes());

                navigate('/profile?tab=recipes')
            }).catch(error => {
                message.error(error);
            })
        })
    };

    const deleteRecipeWarning = (callback) => {
        confirm({
            title: t('recipes.user.title'),
            content: t('recipes.user.content'),
            okText: t('common.yes'),
            okType: 'danger',
            cancelText: t('common.no'),
            onOk() {
                callback()
            },
            onCancel() {
                console.log('Cancel')
            }
        });
    };

    const removeCheatMeal = () => {
        Api.put('/v2/meals/' + meal.id, {is_cheat_meal: false}).then(res => {
            updateMenu(res.data.data);

            dispatch(cheatMealAvailableSuccess({is_cheat_available: true}));
        });
    }

    const renderActions = () => {
        if (!isMeal) {
            return false;
        }

        if (isCheatMeal) {
            return [
                <Tooltip trigger={isMobile ? false : "hover"} key={"cheatmeal"}
                         title={t("meal-plan.actions.cheat-meal")}>
                    <Icon onClick={removeCheatMeal} component={CloseOutlined} key="eat"/>
                </Tooltip>
            ]
        }

        if (meal.has_eaten) {
            return [
                <Tooltip trigger={isMobile ? false : "hover"} key={"tooltipeatendone"}
                         title={!meal.has_eaten ? t('meal-plan.actions.mark-as-eaten') : t('meal-plan.actions.mark-as-not-eaten')}>
                    <Icon onClick={updateEaten} component={meal.has_eaten ? EatenAppleIcon : AppleIcon} key="eat"/>
                </Tooltip>
            ]
        }

        if (disabled) {
            return [
                <Tooltip trigger={isMobile ? false : "hover"} key={"tooltipeaten"}
                         title={!meal.has_eaten ? t('meal-plan.actions.mark-as-eaten') : t('meal-plan.actions.mark-as-not-eaten')}>
                    <Icon onClick={updateEaten} component={meal.has_eaten ? EatenAppleIcon : AppleIcon} key="eat"/>
                </Tooltip>
            ]
        }

        if (hideEaten) {
            return [
                <Tooltip trigger={isMobile ? false : "hover"} key={"tooltipsearch"}
                         title={t('meal-plan.actions.search')}>
                    <Icon onClick={() => setSearchModal(meal)} component={SearchIcon} key="search"/>
                </Tooltip>,
                <Tooltip trigger={isMobile ? false : "hover"} key={"tooltipswap"} title={t('meal-plan.actions.change')}>
                    <Icon onClick={() => changeRecipe(meal, updateMenu)} component={SwapIcon} key="swap"/>
                </Tooltip>,
            ]
        }

        return [
            <Tooltip trigger={isMobile ? false : "hover"} key={"tooltipsearch"} title={t('meal-plan.actions.search')}>
                <Icon onClick={() => setSearchModal(meal)} component={SearchIcon} key="search"/>
            </Tooltip>,
            <Tooltip trigger={isMobile ? false : "hover"} key={"tooltipswap"} title={t('meal-plan.actions.change')}>
                <Icon onClick={() => changeRecipe(meal, updateMenu)} component={SwapIcon} key="swap"/>
            </Tooltip>,
            <Tooltip trigger={isMobile ? false : "hover"} key={"tooltipeaten"}
                     title={!meal.has_eaten ? t('meal-plan.actions.mark-as-eaten') : t('meal-plan.actions.mark-as-not-eaten')}>
                <Icon onClick={updateEaten} component={meal.has_eaten ? EatenAppleIcon : AppleIcon} key="eat"/>
            </Tooltip>,
        ]
    }

    const animations = {
        layout: true,
        initial: 'out',
        animate: 'in',
        variants: {
            in: {y: 0, opacity: 1},
            out: {y: -50, opacity: 0},
        },
    }

    return (
        <motion.div {...animations}>
            <Card
                className="recipe-card"
                cover={
                    <>
                        <AuthorTag recipe={recipeData}/>

                        {isMeal && (
                            <Text className="recipe-card-meal-type">{getMealName(meal.meal_type)}</Text>
                        )}

                        {changeFavorites && !isCheatMeal && (
                            <span className="add-to-favorites" onClick={() => {
                                setIsLiked(!isLiked);
                                changeFavorites(isMeal ? meal : recipe);
                            }}>
                                    <Icon component={isLiked ? HeartIcon : HeartEmptyIcon}/>
                                </span>
                        )}

                        <img className={isCheatMeal ? "cheat-meal-overlay" : ""} onClick={openRecipe}
                             onError={onImageError} alt={recipeData.original_name}
                             src={isCheatMeal ? placeholder : recipeData.image}/>

                        <div onClick={openRecipe}>
                            <RecipeTags isLiked={isLiked} showOnImage={true} recipe={recipeData} meal={meal}/>
                        </div>
                    </>
                }
                actions={renderActions()}
            >
                <Meta
                    avatar={''}
                    title={<div className="card-title"
                                onClick={openRecipe}>{isCheatMeal ? t('meal-plan.actions.cheat-meal') : recipeData.original_name}</div>}
                    description={
                        <div>
                            {!isCheatMeal && (
                                <RecipeTags isLiked={isLiked} recipe={recipeData} meal={meal}/>
                            )}
                        </div>
                    }
                />

                {setAddToMenu && !edit && (
                    <div style={{marginTop: 6}}>
                        <Button size={isMobile || isModal ? "middle" : "large"} onClick={() => setAddToMenu(recipeData)} icon={<PlusOutlined/>}
                                block>{t('recipes.add-to-menu')}</Button>
                    </div>
                )}

                {setAddToMenu && edit && (
                    <div style={{marginTop: 6}}>
                        <Radio.Group size={isMobile || isModal ? "middle" : "large"} value={"small"} style={{width: "100%"}}>
                            <div style={{display: 'flex', width: '100%'}}>
                                <Tooltip key={"add"} title={t('recipes.add-to-menu')}>
                                    <Radio.Button
                                        onClick={() => setAddToMenu(recipeData)}
                                        style={{flex: 1, textAlign: 'center'}}
                                    >
                                        <PlusOutlined key="add"/>
                                    </Radio.Button>
                                </Tooltip>

                                <Tooltip key={"edit"} title={t('create-recipe.title-edit')}>
                                    <Radio.Button
                                        onClick={editRecipe}
                                        style={{flex: 1, textAlign: 'center'}}
                                    >
                                        <EditOutlined key="edit"/>
                                    </Radio.Button>
                                </Tooltip>

                                <Tooltip key={"delete"} title={t('recipes.user.delete')}>
                                    <Radio.Button
                                        onClick={deleteRecipe}
                                        style={{flex: 1, textAlign: 'center'}}
                                    >
                                        <DeleteOutlined key="delete"/>
                                    </Radio.Button>
                                </Tooltip>
                            </div>
                        </Radio.Group>
                    </div>
                )}
            </Card>
        </motion.div>
    )
}

// Fix performance issue rerendering alot
export const RecipeCard = React.memo(RecipeCardDefault, (prevProps, nextProps) => {
    // Rerender only if recipe prop is changed
    if (prevProps.recipe === nextProps.recipe && !nextProps.meal) {
        return true; // props are equal
    }

    return false; // props are not equal -> update the component
});

export default RecipeCard;