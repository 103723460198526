import {CloseOutlined} from "@ant-design/icons";
import React, {useState} from "react";
import {setRecipesKeyword} from "../../actions";
import {useDispatch, useSelector} from "react-redux";
import {Input, AutoComplete, Col, Form} from "antd";
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";
import Api from "../../api";
import {filterObject, getAmplitudeSource, trackEvent} from "../../helpers";

const {Search} = Input;

let timeout = 1000;

const SearchRecipes = ({modal}) => {
    const {t} = useTranslation();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const keyword = useSelector(state => state.recipes.keyword);
    const [options, setOptions] = useState([]);

    const updateKeyword = (e) => {
        dispatch(setRecipesKeyword(e));

        if (!modal) {
            navigate('/cookbook');
        }
    }

    const searchAutoComplete = (keyword) => {
        // TODO: Replace api endpoint
        if (timeout) {
            clearTimeout(timeout);
        }

        if (!keyword) {
            setOptions([]);

            return false;
        }

        timeout = setTimeout(() => {
            Api.get('/v2/recipes/autocomplete?per_page=15&page=1&keywords=' + keyword).then(response => {
                let optionsData = [];

                response.data.data.map(res => {
                    optionsData.push({value: res.original_name, id: res.id})
                })

                setOptions(optionsData)
            });
        }, 200);
    }

    const selectFromDropdown = (value) => {
        let selectedItem = filterObject(options, 'value', value)[0];

        value = value.replace(/[()]/g, '');

        dispatch(setRecipesKeyword(value));

        if (!modal) {
            navigate('/cookbook?id=' + selectedItem.id)
        }
    }

    return (
        <AutoComplete
            className="search-recipes"
            options={options}
            onChange={searchAutoComplete}
            defaultValue={keyword}
            key={keyword}
            onSelect={selectFromDropdown}
            size="large"
        >
            <Search size="large" key={keyword} onSearch={updateKeyword} defaultValue={keyword}
                    placeholder={t("recipes.search.placeholder")}
                    autoFocus={!!keyword}
                    suffix={<>{keyword && (
                        <CloseOutlined onClick={() => updateKeyword('')} style={{color: 'rgba(0,0,0,.45)'}}/>)}</>
                    }
            />
        </AutoComplete>
    )
}

export default SearchRecipes;