import React, {useEffect, useImperativeHandle, useRef, useState} from "react";
import {
    Button,
    Checkbox,
    Col,
    DatePicker,
    Divider,
    Form,
    Input,
    InputNumber,
    message,
    Row,
    Select,
    Space,
    Typography
} from 'antd';
import {config, convert, isEmpty, isImperialSystem, trackEvent, updateAmplitudeUser} from "../helpers";
import ChangePasswordModal from "../components/modals/change-password-modal.jsx";
import moment from 'moment';
import AccountLinks from '../components/settings/account-links.jsx'
import {getMaxWeight, getMinWeight} from "../helpers/user";
import UserAvatar from "../components/settings/user-avatar.jsx"
import DeleteAccount from "../components/settings/delete-account.jsx";
import AppSettings from "../components/settings/app-settings.jsx";

// Redux
import {useDispatch, useSelector} from "react-redux";
import {fetchUserSuccess, getDailyActivites, getGoal, getUserData, getWeightLog} from "../actions";
import Api from "../api";
import {Map} from "immutable";

import {withTranslation} from 'react-i18next';
import * as qs from "qs";
import {useLocation, useNavigate} from "react-router-dom";
import dayjs from "dayjs";
import BlogProfile from "../components/settings/blog-profile";

const {Option} = Select;
const {Title} = Typography;

const PersonalDetails = ({t}) => {
    const location = useLocation();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const user = useSelector(state => state.user.data);
    const [gender, setGender] = useState('');
    const [unitSystem, setUnitSystem] = useState('');
    const [height, setHeight] = useState('');
    const [changePasswordModal, setChangePasswordModal] = useState(false);
    const [file, setFile] = useState(false);
    const [form] = Form.useForm();
    const formRef = useRef(null); // Create a ref to hold the form instance

    const handleBlogFormSubmit = () => {
        if (formRef.current) {
            formRef.current.submit(); // Trigger the form submission
        }
    };

    useEffect(() => {
        window.scrollTo(0, 0);

        trackEvent('opened personal details');

        autoSubmit();
    }, [])


    useEffect(() => {
        if (!isEmpty(user)) {
            setUnitSystem(user.unit_system);
            setGender(user.gender);
            setHeight(user.height);
        }
    }, [user])

    // Auto submit form if param has ?submit=true
    const autoSubmit = () => {
        const button = document.getElementById("save-profile");
        const submit = qs.parse(location.search)['?submit'];

        if (button && submit) {
            button.click();

            navigate('/settings/personal');
        }
    };

    const handleSubmit = (values) => {
        values['birthday'] = dayjs(values.birthday).format("YYYY-MM-DD");

        if (values['height_ft']) {
            values['height'] = convert.feetToCm(form.getFieldValue('height_ft'), form.getFieldValue('height_in'));
        }

        if (isImperialSystem(unitSystem)) {
            values['weight'] = convert.poundsToKg(values['weight_lbs']);
        }

        let changes = {};

        for (let [key, value] of Object.entries(values)) {
            if (user[key] != value) {
                changes[key] = value;
            }
        }

        // Upload user avatar
        if (file) {
            uploadAvatar(file);
        }

        // Use a regular expression to check for emojis
        const emojiRegex = /[\uD800-\uDBFF][\uDC00-\uDFFF]/g;

        if (emojiRegex.test(values['name_f'])) {
            message.error(t('emoji.not-allowed'));
            return false; // Prevent form submission
        }

        Api.put('/v1/user', values).then((res) => {
            message.success(t('settings.personal.update-success'));

            updateAmplitudeUser(res.data);

            trackEvent('updated personal details', changes);

            dispatch(fetchUserSuccess(res.data));

            // Reload daily activities updated weight today
            dispatch(getDailyActivites(moment().format("YYYY-MM-DD")));
            dispatch(getWeightLog());
            dispatch(getGoal());
        }).catch((error) => {
            message.error(error.message);
        });
    };

    const uploadAvatar = (file) => {
        const formData = new FormData();

        if (file) {
            formData.append("avatar", file);
        }

        Api.post('/v1/user/author', formData).then(res => {
            dispatch(getUserData())
        });
    }

    const changeGender = (gender) => {
        setGender(gender)
    };

    const changeUnitSystem = (value) => {
        let height = form.getFieldValue('height');

        if (value == config('unit_system.metric')) {
            height = convert.feetToCm(form.getFieldValue('height_ft'), form.getFieldValue('height_in'));
        }

        setUnitSystem(value)
        setHeight(height)
    };

    const changeBreastFeeding = (value) => {
        if (value == 1) {
            form.setFieldsValue({
                is_breast_feeding: 0,
                goal: 2
            });
        }
    };

    const changeIsPregnant = (value) => {
        if (value == 1) {
            form.setFieldsValue({
                is_pregnant: 0,
            });
        }
    };

    const openChangePasswordModal = () => {
        setChangePasswordModal(true);
    };

    const closeChangePasswordModal = () => {
        setChangePasswordModal(false);
    };

    const toggleNewsletter = (e) => {
        const unsubscribed = !e.target.checked;

        Api.post('/v1/user/newsletters', {unsubscribed: unsubscribed}).then(res => {
            const userData = Map(user);
            const updatedUser = userData.setIn(['unsubscribed'], unsubscribed);

            if (!unsubscribed) {
                trackEvent('subscribed to emails')
            } else {
                trackEvent('unsubscribed from emails')
            }

            dispatch(fetchUserSuccess(updatedUser.toJS()));
        });
    };

    // Expose the form instance to the parent using useImperativeHandle
    useImperativeHandle(formRef, () => form);

    return (
        <div className="personal-details">
            <ChangePasswordModal close={closeChangePasswordModal} visible={changePasswordModal}/>

            <Form
                form={form}
                onFinish={handleSubmit}
                labelCol={{span: 5}}
                wrapperCol={{span: 14}}
                layout="horizontal"
            >

                <Row type="flex" justify="center" align="middle" className="text-center">
                    <UserAvatar file={file} setFile={(e) => setFile(e)} form={form}/>
                </Row>
                <br/>

                <Form.Item
                    label={t('settings.personal.first-name')}
                    name="name_f"
                    initialValue={user.name_f}
                    rules={[
                        {
                            required: true, message: t('settings.personal.first-name-required'),
                        }
                    ]}
                >
                    <Input id={"name_f"}/>
                </Form.Item>

                <Form.Item
                    label={t('settings.personal.last-name')}
                    name="name_l"
                    initialValue={user.name_l}
                >
                    <Input id={"name_l"}/>
                </Form.Item>

                <Form.Item
                    label={t('settings.personal.email')}
                    name="email"
                    initialValue={user.email}
                    rules={[
                        {
                            type: 'email', message: t('settings.personal.email-invalid'),
                        }, {
                            required: true, message: t('settings.personal.email-required')
                        }
                    ]}
                >
                    <Input disabled id={"email"}/>
                </Form.Item>

                <Form.Item
                    label={t('settings.personal.phone')}
                    name="phone"
                    initialValue={user.phone}
                >
                    <Input id={"phone"}/>
                </Form.Item>

                <Form.Item
                    label={t('settings.personal.gender')}
                    name="gender"
                    initialValue={user.gender}
                >
                    <Select onChange={changeGender} style={{width: 150}}>
                        <Option value="male">{t('settings.personal.gender-male')}</Option>
                        <Option value="female">{t('settings.personal.gender-female')}</Option>
                    </Select>
                </Form.Item>

                {
                    user.birthday && (
                        <Form.Item
                            label={t('settings.personal.birthday')}
                            name="birthday"
                            initialValue={dayjs(user.birthday, 'YYYY-MM-DD')}
                        >
                            <DatePicker format={['DD.MM.YYYY', 'YY-MM-DD']}
                                        disabledDate={current => {
                                            return current > moment().subtract(15, 'years');
                                        }}/>
                        </Form.Item>
                    )
                }

                {
                    ((gender != '') ? (gender == 'female') : (user.gender == 'female')) && (
                        <>
                            <Form.Item
                                label={t('settings.personal.pregnant')}
                                name="is_pregnant"
                                initialValue={user.is_pregnant ? 1 : 0}
                            >
                                <Select onChange={changeBreastFeeding}>
                                    <Option value={0}>{t('common.no')}</Option>
                                    <Option value={1}>{t('common.yes')}</Option>
                                </Select>
                            </Form.Item>
                            <Form.Item
                                label={t('settings.personal.breastfeeding')}
                                name="is_breast_feeding"
                                initialValue={user.is_breast_feeding ? 1 : 0}
                            >
                                <Select onChange={changeIsPregnant}>
                                    <Option value={0}>{t('common.no')}</Option>
                                    <Option value={1}>{t('common.yes')}</Option>
                                </Select>
                            </Form.Item>
                        </>
                    )
                }

                {
                    config('feature.unit_system_enabled') && (
                        <Form.Item
                            label={t('settings.personal.unit-system')}
                            name="unit_system"
                            initialValue={user.unit_system}
                        >
                            <Select onChange={changeUnitSystem} style={{width: 150}}>
                                <Option
                                    value={config('unit_system.metric')}>{t('settings.personal.unit-system-metric')}</Option>
                                <Option
                                    value={config('unit_system.imperial')}>{t('settings.personal.unit-system-imperial')}</Option>
                            </Select>
                        </Form.Item>
                    )
                }

                {(unitSystem == config('unit_system.metric')) ? (
                    <Form.Item
                        label={<>{t('settings.personal.weight')}<span>({t('unit.kg')})</span></>}
                        name="weight"
                        initialValue={user.weight}
                        rules={[
                            {required: true, message: t('goals.weight.modal.value-required')},
                            {
                                type: "number",
                                min: getMinWeight(unitSystem),
                                max: getMaxWeight(unitSystem),
                                message: t('goals.weight.modal.error', {
                                    min: getMinWeight(unitSystem),
                                    max: getMaxWeight(unitSystem)
                                })
                            }
                        ]}
                    >
                        <InputNumber step={0.1} type="number" min={getMinWeight(unitSystem)}
                                     max={getMaxWeight(unitSystem)}/>
                    </Form.Item>

                ) : (
                    <Form.Item
                        label={<>{t('settings.personal.weight')}
                            <span>({t('unit.lbs')})</span></>}
                        name="weight_lbs"
                        initialValue={+convert.kgToPounds(user.weight)}
                        rules={[
                            {required: true, message: t('goals.weight.modal.value-required')},
                            {
                                type: "number",
                                min: getMinWeight(unitSystem),
                                max: getMaxWeight(unitSystem),
                                message: t('goals.weight.modal.error', {
                                    min: getMinWeight(unitSystem),
                                    max: getMaxWeight(unitSystem)
                                })
                            }
                        ]}
                    >
                        <InputNumber step={0.1} type="number" min={getMinWeight(unitSystem)}
                                     max={getMaxWeight(unitSystem)}/>
                    </Form.Item>
                )}

                {
                    (unitSystem == config('unit_system.metric')) && (
                        <Form.Item
                            label={<>{t('settings.personal.height')} <span>({t('unit.cm')})</span></>}
                            name="height"
                            rules={[{required: true, message: t('settings.personal.height-required')}]}
                            initialValue={height ? height : user.height}
                        >
                            <InputNumber style={{width: 150}} min={130} max={220}/>
                        </Form.Item>
                    )
                }

                {
                    (unitSystem == config('unit_system.imperial')) && (
                        <>
                            <Form.Item
                                label={t('unit.ft')}
                                name="height_ft"
                                rules={[{required: true, message: 'Please insert height'}]}
                                initialValue={height ? convert.cmToFeet(height)[0] : convert.cmToFeet(user.height)[0]}
                            >
                                <InputNumber type="number" placeholder={t('unit.ft')} min={4}
                                             max={8}/>
                            </Form.Item>
                            <Form.Item
                                label={t('unit.inch')}
                                name="height_in"
                                initialValue={height ? convert.cmToFeet(height)[1] : convert.cmToFeet(user.height)[1]}
                            >
                                <InputNumber type="number" placeholder={t('unit.inch')} min={0}
                                             max={12}/>
                            </Form.Item>
                        </>
                    )
                }

                <Form.Item label={t('settings.personal.newsletters')}>
                    <Checkbox className="toggle-newsletter" checked={!user.unsubscribed}
                              onChange={toggleNewsletter}></Checkbox>
                </Form.Item>
            </Form>

            <BlogProfile submitForm={handleBlogFormSubmit} openChangePasswordModal={openChangePasswordModal}/>

            <AppSettings/>
            <br/>
            <AccountLinks/>
            <br/>
            <DeleteAccount/>

        </div>
    );
}


export default withTranslation()(PersonalDetails);
