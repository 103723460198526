import React, {useEffect} from "react";
import {Col, Divider, Row, Skeleton, Typography} from "antd";
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from "react-redux";
import {getNewRecipes} from "../../actions";
import RecipeCard from "../meal-plan/recipe-card";

const { Title } = Typography;

const NewRecipes = ({modal, openRecipe}) => {
    const {t} = useTranslation();
    const dispatch = useDispatch();
    const newRecipes = useSelector(state => state.recipes.new_recipes);
    const loading = useSelector(state => state.recipes.new_loading);

    useEffect(() => {
        if (newRecipes.length === 0) {
            dispatch(getNewRecipes());
        }
    }, []);

    if (!loading && newRecipes.length < 5) {
        return false;
    }

    return (
        <Row>
            <Col span={24} md={24}>
                <Divider orientation="left">
                    <Title style={{margin: 0}} level={4}>{t('new.recipes')}</Title>
                </Divider>

                <Row gutter={20} className="scroll-wrapper">
                    {!loading && newRecipes.slice(0, 6).map(data => {
                        return (
                                <Col key={data.id} span={14} md={8} xxl={4}>
                                    <RecipeCard openRecipeModal={openRecipe} recipe={data}/>
                                </Col>
                        )
                    })}

                    {loading && (
                        <>
                            {[1, 2, 3, 4, 5, 6].map(index => {
                                return (
                                    <Col key={index} span={14} md={8} xxl={4}>
                                        <Skeleton active className="recipe-skeleton top-recipe"
                                                  paragraph={{rows: 3, width: "30%"}} title={false}/>
                                    </Col>
                                )
                            })}
                        </>
                    )}
                </Row>
            </Col>
        </Row>
    );
};

export default NewRecipes;
