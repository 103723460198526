import {Button, Divider, Form, Input, message, Space, Typography} from "antd";
import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import Api from "../../api";

const {Title} = Typography;

const BlogProfile = ({ submitForm, openChangePasswordModal }) => {
    const [author, setAuthor] = useState('');
    const {t} = useTranslation();

    useEffect(() => {
        loadAuthor();
    }, [])

    const loadAuthor = () => {
        Api.get('/v1/user/author').then((response) => {
            setAuthor(response.data.data);
        });
    };

    const handleSubmit = (values) => {
        submitForm();

        Api.post('/v1/user/author', values).then(res => {
            //message.success(t('blogs.edit-profile.success'));

        }).catch(error => {
            if (error.response.data.code == 1001) {
                return message.error(t('blogs.edit-profile.error.slug-taken'));
            }

            if (error.response.data.errors && error.response.data.errors.avatar) {
                return message.error(t('blogs.edit-profile.error.avatar.size'));
            } else {
                return message.error(t('blogs.edit-profile.error.slug'));
            }
        });
    }

    if (!author) {
        return false;
    }

    return (
        <Form
            onFinish={handleSubmit}
            labelCol={{span: 5}}
            wrapperCol={{span: 14}}
            layout="horizontal"
        >
            <Form.Item
                label={t('blogs.edit-profile.name')}
                name="name"
                initialValue={author.name}
                rules={[
                    {required: true, message: t('settings.personal.first-name-required'),}
                ]}
            >
                <Input id={"name"}/>
            </Form.Item>

            <Form.Item
                label={t('blogs.edit-profile.username')}
                name="slug"
                initialValue={author.slug}
            >
                <Input id={"slug"}/>
            </Form.Item>

            <Form.Item wrapperCol={{md: {offset: 8, span: 16}}}>
                <Space>

                    <Button id="save-profile" size="large" type="primary"
                            htmlType="submit">{t('common.save')}</Button>

                    <Button size="large"
                            onClick={openChangePasswordModal}>{t('settings.personal.change-password')}</Button>
                </Space>
            </Form.Item>
        </Form>
    )
}

export default BlogProfile;