import React, {useState} from "react";
import {message, Upload} from "antd";
import {fixRotationOfFile} from "../../helpers";
import {useSelector} from "react-redux";
import {withTranslation} from "react-i18next";
import Icon from "@ant-design/icons";

function getBase64(img, callback) {
    const reader = new FileReader();

    reader.addEventListener('load', () => callback(reader.result));
    reader.readAsDataURL(img);
}

// Simulate successful upload for antd upload component
const dummyRequest = ({file, onSuccess}) => {
    setTimeout(() => {
        onSuccess("ok");
    }, 0);
};

const UserAvatar = ({form, t, file, setFile}) => {
    const user = useSelector(state => state.user.data);
    const [loading, setLoading] = useState(false);
    const [imageUrl, setImageUrl] = useState('');

    const handleChange = info => {
        setLoading(true);

        fixRotationOfFile(info.file.originFileObj).then(blob => {
            const updatedFile = new File([blob], info.file.originFileObj.name, {type: blob.type, lastModified: Date.now()});

            getBase64(updatedFile, imageUrl => {
                setImageUrl(imageUrl);
                setFile(updatedFile);
                setLoading(false);
            });
        });
    };

    // Validate image file type and size
    const beforeUpload = (file) => {
        return true;
        const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';

        if (!isJpgOrPng) {
            message.error(t('blogs.edit-profile.error.avatar.type'));
        }

        const isLt2M = file.size / 1024 / 1024 < 6;

        if (!isLt2M) {
            message.error(t('blogs.edit-profile.error.avatar.limit'));
        }

        return isJpgOrPng && isLt2M;
    };

    const uploadButton = (
        <div>
            <Icon type={loading ? 'loading' : 'plus'} />
            <div className="ant-upload-text">{t('blogs.edit-profile.upload')}</div>
        </div>
    );
    return (
        <Upload
            name="avatar"
            listType="picture-card"
            className="avatar-uploader"
            showUploadList={false}
            customRequest={dummyRequest}
            beforeUpload={beforeUpload}
            onChange={handleChange}
        >
            {(imageUrl || user.avatar) ? <img style={{maxWidth: "100%", borderRadius: "50%"}} src={imageUrl ? imageUrl : user.avatar} alt="avatar"/> : uploadButton}
        </Upload>
    )
}

export default withTranslation()(UserAvatar);