import React, { createContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getNotifications, getStartGuide, getUserLevels } from './actions';
import { config, getCookie, isEmpty } from './helpers';
import Echo from 'laravel-echo';
import LevelUpModal from './components/modals/level-up-modal';
import {message} from "antd";

const WebSocketContext = createContext(null);

window.Pusher = require('pusher-js');

export { WebSocketContext };

export const getChannelPrefix = () => {
    const domain = config('domain');
    const customApi = getCookie('custom_api');

    if (customApi && config('domain')) {
        if (customApi.includes('.ee')) {
            return 'et';
        }
    }

    switch (domain) {
        case 'test':
            return 'test';
        case 'com':
            return 'en';
        case 'fi':
            return 'fi';
        default:
            return 'et';
    }
};

const WebSocketProvider = ({ children }) => {
    const dispatch = useDispatch();
    const levels = useSelector((state) => state.levels.data);
    const user = useSelector((state) => state.user.data);
    const [levelUpData, setLevelUpData] = useState(false);
    const [connected, setConnected] = useState(false);
    const [ws, setWs] = useState({ socket: null });
    const [wsStatus, setWsStatus] = useState('connected');

    const channelPrefix = getChannelPrefix();
    const websocket_url = config('api.websocket');
    const websocket_key = config('api.websocket_key');

    useEffect(() => {
        if (!connected && websocket_url) {
            const token = getCookie('fl_access_token');

            if (user.user_id && !isEmpty(levels)) {
                const newSocket = new Echo({
                    broadcaster: 'pusher',
                    key: websocket_key,
                    cluster: 'mt1',
                    forceTLS: false,
                    wsPort: 6001,
                    wssPort: 6001,
                    enabledTransports: ['ws', 'wss'],
                    wsHost: websocket_url,
                    authEndpoint: (getCookie('custom_api') ? getCookie('custom_api') : config('api.url')) + 'broadcasting/auth',
                    auth: {
                        headers: {
                            Authorization: `Bearer ${token}`,
                        },
                    },
                });

                // 🛑 Handle WebSocket connection errors
                if (newSocket.connector.pusher) {
                    const pusherConnection = newSocket.connector.pusher.connection;

                    pusherConnection.bind('state_change', function(states) {
                        console.log('State changed:', states);

                        if (states.current === "connected") {
                            console.log("✅ WebSocket connected successfully.");
                            setWsStatus('connected');
                        } else {
                            console.log("WebSocket is unavailable! Retrying...");
                            setWsStatus('unavailable');
                        }
                    });
                }

                newSocket.private(`${channelPrefix}.user.${user.user_id}.levels`)
                    .listen('.LevelUp', (e) => {
                        setTimeout(() => {
                            setLevelUpData(e);
                        }, 1000);
                        dispatch(getUserLevels());
                    });

                newSocket.private(`${channelPrefix}.user.${user.user_id}.levels`)
                    .listen('.ReceivedPoints', (e) => {
                        if (window.location.href.includes('/profile')) {
                            dispatch(getUserLevels());
                        }
                    });

                newSocket.private(`${channelPrefix}.user.${user.user_id}.notifications`)
                    .listen('.Notifications', (e) => {
                        dispatch(getNotifications());
                    });

                newSocket.channel(`${channelPrefix}.notifications`)
                    .listen('.GlobalNotifications', (e) => {
                        dispatch(getNotifications());
                    });

                newSocket.private(`${channelPrefix}.user.${user.user_id}.start-guide`)
                    .listen('.startGuideCompleted', (e) => {
                        console.log('Startguide Completed event received');
                        dispatch(getStartGuide());
                    });

                setConnected(true);
                setWs({ socket: newSocket });
            }
        }
    }, [connected, websocket_url, user.user_id, levels, channelPrefix, dispatch]);

    // Cleanup WebSocket on component unmount
    useEffect(() => {
        return () => {
            if (ws?.socket) {
                ws.socket.disconnect();
            }

            if (ws?.socket?.connector?.pusher) {
                const pusherConnection = ws.socket.connector.pusher.connection;
                pusherConnection.unbind("error");
                pusherConnection.unbind("disconnected");
                pusherConnection.unbind("connecting");
                pusherConnection.unbind("connected");
                pusherConnection.unbind("failed");
            }
        };
    }, [ws]);

    return (
        <WebSocketContext.Provider value={{ws, wsStatus}}>
            {children}
            <LevelUpModal data={levelUpData} />
        </WebSocketContext.Provider>
    );
};

export default WebSocketProvider;