import React, {useEffect, useState} from "react";
import {Button, Col, Divider, message, Row, Typography} from 'antd';
import {config, isApp, ReactNativeMessage, safeJSONParse, trackEvent} from "../../helpers";
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props'

// Redux
import {useDispatch, useSelector} from "react-redux";
import {fetchUserSuccess, getUserData} from "../../actions";
import Api from "../../api";
import {Map} from "immutable";
import {withTranslation} from 'react-i18next';
import GoogleButton from "../login/google-button";
import Icon from "@ant-design/icons";
import {GoogleLogo} from "../../icons";

const {Title} = Typography;

const AccountLinks = ({t}) => {
    const dispatch = useDispatch();
    const user = useSelector(state => state.user.data);
    const [loading_fb, setLoadingFb] = useState(false);
    const [loading_google, setLoadingGoogle] = useState(false);

    useEffect(() => {
        if (isApp()) {
            const body = isApp('ios') ? window : document;

            body.addEventListener("message", handleNativeLogin);

            return (() => {
                body.removeEventListener("message", handleNativeLogin);
            })
        }
    }, []);

    const handleNativeLogin = (e) => {
        if (e && e.data) {
            const data = safeJSONParse(e.data);
            if (data && data.fb_token) {
                facebookAuth({accessToken: data.fb_token, id: data.user_id, authToken: data.auth_token});
            }
        }
    };

    const facebookAuth = (response) => {
        setLoadingFb(true);

        if (!response.accessToken) {
            setLoadingFb(false);

            return message.error(t('settings.personal.facebook.login-failed'));
        }

        if (!user.facebook_id) {
            linkFacebook(response.accessToken, response.id, response.authToken)
        } else {
            unlinkFacebook();
        }
    };

    const linkFacebook = (token, id, authToken) => {
        Api.post('/v1/user/facebook', {fb_token: token, auth_token: authToken}).then(res => {
            const userData = Map(user);

            const updatedUser = userData.setIn(['facebook_id'], id);

            dispatch(fetchUserSuccess(updatedUser.toJS()));

            trackEvent('connected FB account');

            setLoadingFb(false);

            message.success(t('settings.personal.facebook.connect-successful'));
        }).catch(error => {
            if (error && error.response && error.response.status == 403) {
                message.error(t('settings.personal.facebook.already-connected'));
            }

            setLoadingFb(false);
        });

    };

    const unlinkFacebook = () => {
        Api.delete('/v1/user/facebook').then(res => {
            const userData = Map(user);

            const updatedUser = userData.setIn(['facebook_id'], null);

            dispatch(fetchUserSuccess(updatedUser.toJS()));

            trackEvent('disconnected FB account');

            setLoadingFb(false);

            message.error(t('settings.personal.facebook.disconnect-successful'));
        });
    };

    const linkGoogle = (response) => {
        Api.post('/v1/user/google', {google_token: response.accessToken}).then(res => {
            const userData = Map(user);

            const updatedUser = userData.setIn(['google_id'], true);

            dispatch(fetchUserSuccess(updatedUser.toJS()));

            trackEvent('connected Google account');

            setLoadingGoogle(false);

            message.success(t('settings.personal.google.connect-successful'));
        }).catch(error => {
            console.log(error);

            if (error && error.response && error.response.status == 403) {
                message.error(t('settings.personal.google.already-connected'));
            } else {
                message.error('error');
            }

            setLoadingGoogle(false);
        });
    }

    const unlinkGoogle = () => {
        Api.delete('/v1/user/google').then(res => {
            const userData = Map(user);

            const updatedUser = userData.setIn(['google_id'], null);

            dispatch(fetchUserSuccess(updatedUser.toJS()));

            trackEvent('disconnected Google account');

            setLoadingGoogle(false);

            message.error(t('settings.personal.google.disconnect-successful'));
        });
    };

    return (
        <div>
            <Divider orientation="left">
                <Title style={{margin: 0}} level={4}>{t('settings.personal.connect-accounts')}</Title>
            </Divider>

            <Row type="flex" justify="center" className="connect-accounts-buttons">
                <Col span={24} lg={12}>
                    {config('feature.google_login') && (
                        <>
                            {user.google_id ? (
                                <Button block style={{marginTop: 16}} size="large"
                                        className="login-form-button"
                                        onClick={unlinkGoogle}
                                        icon={<Icon component={GoogleLogo}/>}>{t('unlink.google')}
                                </Button>
                            ) : (
                                <GoogleButton callback={linkGoogle} type={"connect"}/>
                            )}
                        </>
                    )}

                    <FacebookLogin
                        appId={config('facebook.app_id')}
                        isMobile={false}
                        autoLoad={false}
                        fields="name,email,picture"
                        callback={facebookAuth}
                        render={renderProps => (
                            <Button loading={loading_fb}
                                    className={"facebook-button " + (user.facebook_id ? 'connected' : '')}
                                    size="large"
                                    onClick={isApp() ? () => ReactNativeMessage('FACEBOOK_LOGIN') : renderProps.onClick}>
                                {user.facebook_id ? t('settings.personal.facebook.disconnect') : t('settings.personal.facebook.connect')}</Button>
                        )}
                    />
                </Col>
            </Row>
        </div>
    );
}

export default withTranslation()(AccountLinks);
