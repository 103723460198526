import React, {useEffect, useState} from "react";
import {Card, Typography, Divider, List} from "antd";
import Api from "../../api";
const {Title} = Typography;

const RewardsCard = ({id}) => {
    const [rewardsData, setRewardsData] = useState('');

    useEffect(() => {
        Api.get('/v2/blog/page?slug=sales-page-' + id).then(response => {
            let pageData = response.data.data; // Fetch the page data

            // Convert fields.list to an array if it exists
            const listArray = pageData.fields.rewards.list
                ? Object.entries(pageData.fields.rewards.list).reduce((acc, [key, value]) => {
                    const [index, field] = key.split('_');
                    if (!acc[index]) acc[index] = {};
                    acc[index][field] = value;
                    return acc;
                }, []) : '';

            setRewardsData(listArray); // Set the page state with the updated data
        })
    }, [id])

    if (!rewardsData) {
        return;
    }

    return (
        <Card>
            <Divider orientation="left">
                <Title style={{margin: 0}} level={4}>Auhinnad</Title>
            </Divider>

            <List
                itemLayout="horizontal"
                dataSource={rewardsData}
                renderItem={(item) => (
                    <List.Item>
                        <List.Item.Meta
                            avatar={<img style={{maxWidth: 24}} src={item.image}/>}
                            title={<strong>{item.title}</strong>}
                            description={item.description}
                        />
                    </List.Item>
                )}
            />
        </Card>
    );
};

export default RewardsCard;