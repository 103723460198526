export const defaultSortOptions = [
    {
        id: 10,
        value: "random",
        type: "desc",
        name: "recipes.sort.random",
        group_id: 1
    },
    /*
    {
        id: 8,
        value: "top",
        type: "desc",
        name: "recipes.sort.top",
        group_id: 4
    },
    */
    {
        id: 7,
        value: "most-liked",
        type: "desc",
        name: "recipes.sort.favorites",
        group_id: 1
    },
    {
        id: 1,
        value: "created_at",
        type: "desc",
        name: "recipes.sort.new-first",
        group_id: 1
    },
    {
        id: 2,
        value: "created_at",
        type: "asc",
        name: "recipes.sort.old-first",
        group_id: 1
    },
    {
        id: 3,
        value: "price",
        type: "asc",
        name: "recipes.sort.cheap",
        group_id: 2
    },
    {
        id: 5,
        value: "preparation_time",
        type: "asc",
        name: "recipes.sort.short-prep",
        group_id: 3
    },
];

export const publicSortOptions = [
    {
        id: 10,
        value: "random",
        type: "desc",
        name: "recipes.sort.random",
        group_id: 1
    },
    {
        id: 7,
        value: "most-liked",
        type: "desc",
        name: "recipes.sort.favorites",
        group_id: 1
    },
    {
        id: 1,
        value: "created_at",
        type: "desc",
        name: "recipes.sort.new-first",
        group_id: 1
    },
    {
        id: 2,
        value: "created_at",
        type: "asc",
        name: "recipes.sort.old-first",
        group_id: 1
    },
]