import React, {useEffect, useState} from "react";
import {
    Card,
    Typography,
    Button,
    Row,
    Col,
    Tag,
    Statistic,
    Avatar,
    Progress,
    Space,
    Divider,
    message,
    Popover, Image
} from "antd";
import moment from "moment";
import {useResizeEffect} from "../../resize-effect";
import {AntDesignOutlined, InfoCircleOutlined, InfoOutlined, UserOutlined} from "@ant-design/icons";
import Api from "../../api";
import {filterObject} from "../../helpers";
import {useSelector} from "react-redux";
import {useTranslation} from "react-i18next";
import CountdownTimer from "./countdown-timer";
import {Link} from "react-router-dom";

const {Title, Text} = Typography;
const {Countdown} = Statistic;

const StepChallengeCard = ({competition, hasJoinedCompetition, setHasJoined}) => {
    const startDate = competition.begin_date ? moment(competition.begin_date) : moment("2025-01-01");
    const endDate = competition.end_date ? moment(competition.end_date) : moment("2025-02-28");
    const today = moment(); // Assuming today is the 3rd day of challenge
    const totalDays = endDate.diff(startDate, "days");
    const daysPassed = today.diff(startDate, "days");
    const progressPercent = (daysPassed / totalDays) * 100;
    const isMobile = useResizeEffect();
    const isChallengedEnd = today.isAfter(endDate); // Returns true if the challenge has ended
    const user = useSelector(state => state.user.data);
    const {t} = useTranslation();

    const joinChallenge = () => {
        // Remove user from competition
        if (hasJoinedCompetition) {
            Api.delete('/v3/user/competitions/' + competition.id).then(res => {
                message.info('Lahkusid võistlusest')

                setHasJoined(false);
            });
        } else {
            // Add user to competition
            Api.post('/v3/user/competitions/' + competition.id).then(res => {
                setHasJoined(true);

                message.success(t('challenge.joined'))
            });
        }
    }

    return (
        <Card cover={<Image
            style={{
                width: "100%",
                objectFit: "cover",
                height: isMobile ? 200 : 160
            }}
            src={competition.image}
        />} style={{borderRadius: 10}}>
            <Row gutter={[16, 16]} align="middle">
                <Col flex="auto">
                    <Space direction="vertical" style={{width: "100%"}}>

                        <Title level={4} style={{margin: 0}}><strong>{competition.name}</strong></Title>
                        <Text
                            type="secondary">{startDate.format("D. MMM, YYYY")} - {endDate.format("D. MMM, YYYY")}</Text>
                        <Progress strokeColor={{'0%': '#ee7752', '50%': '#e73c7e'}}    percentPosition={{type: 'hidden'}}
                                  percent={parseInt(progressPercent)} status="active"/>

                        <Space>
                            {!isChallengedEnd &&
                                <Button type={hasJoinedCompetition ? "default" : "primary"} onClick={joinChallenge}>{hasJoinedCompetition ? t('challenge.quit') : t('challenge.join')}</Button>}

                            <Link to={"/sales-page/" + competition.id}>
                                <Button type="default" icon={<InfoCircleOutlined/>}>{t('challenge.info')}</Button>
                            </Link>
                        </Space>
                    </Space>
                </Col>
            </Row>
        </Card>
    );
};

export default StepChallengeCard;
