import React, {useEffect, useState} from "react";
import {Card, Typography, Button, Row, Col, Tag, Divider, Space, Avatar, message} from "antd";
import SheetModal from "../components/blocks/sheet-modal";
import PaymentMethods from "../components/payment/payment-methods";
import {useTranslation} from "react-i18next";
import ChallengeCard from "../components/challenges/challenge-card";
import Api from "../api";
import PurchaseChallengeModal from "../components/challenges/purchase-challenge-modal";

const {Title, Text} = Typography;
const {CheckableTag} = Tag;

const categories = [
    {
        key: "steps",
        label: "Sammud"
    },
    {
        key: "bingo",
        label: "Bingo"
    },
    {
        key: "free",
        label: "Tasuta"
    },
    {
        key: "paid",
        label: "Tasulised"
    },
];

const ChallengesPage = () => {
    const [selectedCategory, setSelectedCategory] = useState(null);
    const [showPurchase, setShowPurchase] = useState(null);
    const [competitions, setCompetitions] = useState([]);

    useEffect(() => {
        Api.get('/v3/competitions').then(response => {
            setCompetitions(response.data.data);
        })
    }, []);

    const handleCategoryChange = (category) => {
        setSelectedCategory(selectedCategory === category ? null : category);
    };

    return (
        <div id="challenges">
            <PurchaseChallengeModal showPurchase={!!showPurchase} setShowPurchase={setShowPurchase}
                                    challenge={showPurchase}/>

            <Row type="flex" justify="center">
                <Col span={24} md={24} lg={24} xxl={20}>
                    <div className="text-center" >
                        {categories.map((category) => (
                            <CheckableTag
                                className={"recipe-checkable-tag"}
                                key={category.key}
                                checked={selectedCategory === category.key}
                                onChange={() => handleCategoryChange(category.key)}
                            >
                                {category.label}
                            </CheckableTag>
                        ))}
                    </div>

                    <Divider orientation="left">
                        <Title style={{margin: 0}} level={4}>Väljakutsed</Title>
                    </Divider>

                    <Row gutter={[16, 16]}>
                        {competitions.filter(challenge => {
                            if (!selectedCategory) return true; // Show all if no filter is selected
                            if (selectedCategory === "free") return !challenge.is_paid;
                            if (selectedCategory === "paid") return challenge.is_paid;
                            return challenge?.type === selectedCategory;
                        }).map((challenge, index) => {
                            return <Col key={index} xs={24} sm={12} md={12} xl={6}>
                                <ChallengeCard challenge={challenge} setShowPurchase={setShowPurchase}/>
                            </Col>
                        })}
                    </Row>
                </Col>
            </Row>
        </div>
    );
};

export default ChallengesPage;