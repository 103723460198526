import {Col, Skeleton} from "antd";

const RecipeCardSkeleton = () => {
    return (
        <Col span={12} md={8} lg={6}>
            <Skeleton active className="recipe-skeleton" paragraph={{rows: 3, width: "30%"}} title={false}/>
        </Col>
    )
}

export default RecipeCardSkeleton;