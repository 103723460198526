import Api from "../api";
import {message} from "antd";
import {getAmplitudeMealName, getAmplitudeSource, trackEvent} from "../helpers";
import i18next from "i18next";
import {Map, setIn} from "immutable"

export const changeEaten = (meal, callback) => {
    const status = !meal.has_eaten ? 1 : 0;

    Api.put('/v2/meals/' + meal.id, {has_eaten: status}).then((res) => {
        const updatedMeal = res.data.data;

        if (status) {
            trackEvent('marked meal eaten', {
                meal_type: getAmplitudeMealName(updatedMeal.meal_type),
                source: getAmplitudeSource()
            })
        } else {
            trackEvent('unmarked meal eaten', {
                meal_type: getAmplitudeMealName(updatedMeal.meal_type),
                source: getAmplitudeSource()
            })
        }

        callback(updatedMeal);
    });
};

let timeout = 3000;
let counter = 0;

export const changeRecipe = (meal, callback) => {
    counter++;

    Api.post("/v2/meals/" + meal.id + "/random").then(res => {
        const newMeal = res.data.data;
        const source = getAmplitudeSource();

        if (timeout) {
            clearTimeout(timeout);
        }

        // Send event to amplitude with 3 sec delay and count spam clicks
        timeout = setTimeout(() => {
            trackEvent('swapped meal', {
                old: meal.recipe.original_name.toLowerCase(),
                new: newMeal.recipe.original_name.toLowerCase(),
                clicks: counter,
                source: source
            });

            counter = 0;
        }, 3000);

        callback(newMeal);
    });
};

export const updateFavoriteMeal = (meal, callback) => {
    const data = {
        recipe_id: meal.recipe.id
    };

    if (!meal.recipe.is_favorite) {
        Api.post('/v1/user/favorite-recipes', data).then(() => {
            const mealCopy = Map(meal);
            const updatedMeal = mealCopy.setIn(['recipe', 'is_favorite'], true);

            trackEvent('added to favourites', {
                source: 'meal plan',
                recipe_name: meal.recipe.original_name.toLowerCase()
            });

            message.success(i18next.t('recipes.favorites.add-success'));

            callback(updatedMeal.toJS());
        });
    } else {
        Api.delete('/v1/user/favorite-recipes', data).then(() => {
            const mealCopy = Map(meal);
            const updatedMeal = mealCopy.setIn(['recipe', 'is_favorite'], false);

            trackEvent('removed from favourites', {
                source: 'meal plan',
                recipe_name: meal.recipe.original_name.toLowerCase()
            });

            message.error(i18next.t('recipes.favorites.remove-success'));

            callback(updatedMeal.toJS());
        });
    }
};

export const updateFavorites = (recipe, recipes, callback) => {
    let isFavorite = !recipe.is_favorite;
    let type = isFavorite ? 'post' : 'delete';

    const recipeIndex = recipes.findIndex(function (data) {
        return data.id === recipe.id;
    });

    Api[type]('/v1/user/favorite-recipes', {recipe_id: recipe.id}).then(() => {
        if (isFavorite) {
            message.success(i18next.t('recipes.favorites.add-success'));
            trackEvent('added to favourites', {
                source: 'cookbook',
                recipe_name: recipe.original_name.toLowerCase()
            });
        } else {
            message.error(i18next.t('recipes.favorites.remove-success'));
            trackEvent('removed from favourites', {
                source: 'cookbook',
                recipe_name: recipe.original_name.toLowerCase()
            });
        }

        const updatedRecipes = setIn(recipes, [recipeIndex, 'is_favorite'], isFavorite);

        return callback(updatedRecipes);
    });
};

export const mergeDuplicateIngredients = (arr) => {
    let output = arr.reduce(function(accumulator, cur) {
        let name = cur.id, found = accumulator.find(function(elem) {
            return elem.id == name
        });
        if (found) found.amount += cur.amount;
        else accumulator.push(cur);
        return accumulator;
    }, []);

    return output;
}

export const getProgressClass = (energy) => {
    if (energy > 104) {
        return "error"
    }

    if (energy > 95) {
        return "success"
    }

    return ''
}

export const recipeIsComplete = (energy, dailyTdee) => {
    if (!energy || !dailyTdee) {
        return true;
    }

    const percentage = energy / dailyTdee * 100;

    return (percentage < 105 && percentage > 95)
}
