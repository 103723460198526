import React, {useEffect, useState} from "react";
import {
    Typography,
    Button,
    List,
    Checkbox,
    Row,
    Col,
    Card,
    Input, message, Tabs, Space, Progress,
} from "antd";
import {useNavigate, useParams} from "react-router-dom";
import Icon, {
    ClockCircleOutlined, LoadingOutlined, MessageOutlined,
} from "@ant-design/icons";
import Courses from "./courses";
import {round} from "../helpers";
import {useResizeEffect} from "../resize-effect";
import Api from "../api";
import {useTranslation} from "react-i18next";
import ReactPlayer from "react-player";
import {useSelector} from "react-redux";

const {Title, Text} = Typography;
const {TextArea} = Input;

const SingleCourseView = () => {
    const {t} = useTranslation();
    const navigate = useNavigate();
    const {id} = useParams();
    const isMobile = useResizeEffect();
    const [course, setCourse] = useState('');
    const [loading, setLoading] = useState(false);
    const user = useSelector(state => state.user.data);
    const hasAccess = ((user.promotional_gifts && user.promotional_gifts.includes('https://app.fitlap.ee/courses')) || user.fitlap_plus);

    useEffect(() => {
        setLoading(true);

        window.scrollTo(0, 0);

        loadCourse();
    }, []);

    const loadCourse = () => {
        Api.get('/v2/courses/' + id).then(res => {
            if (res.data.status === 1 && hasAccess) {
                setCourse(res.data);
            } else {
                message.error(t('course.no-access'))
                navigate('/courses')
            }


            setLoading(false);
        })
    }

    const handleTaskToggle = (task, checked) => {
        Api.put(`/v2/tasks/${task.id}/complete`, {completed: !task.completed}).then((res) => {
            message.success(t(!task.completed ? 'task.completed' : 'task.uncompleted'));

            setCourse(res.data);
        }).catch(err => {
            console.error(err)
        });
    };

    const completedTasksCount = course && course.tasks.filter(task => task.completed).length;

    return (
        <div>
            <Row type="flex" justify="center">
                <Col span={24} md={20} xxl={16}>

                    <Row type="flex" justify="center" gutter={20}>
                        <Col span={24} lg={18}>
                            {!isMobile && (
                                <Title level={isMobile ? 4 : 1}>{course.title}</Title>
                            )}
                            <Card className="video-card-iframe" cover={!loading ?
                                <ReactPlayer
                                    url={course.video}
                                    style={{borderRadius: 12, overflow: "hidden"}}
                                    width="100%"
                                    controls={true}
                                    height={isMobile ? "200px" : "387px"}
                                /> : <div style={{
                                    height: isMobile ? "200px" : "387px",
                                    display: "flex",
                                    justifyContent: "center"
                                }}><LoadingOutlined
                                    style={{fontSize: 30}} spin/></div>
                            }>

                                {isMobile && (
                                    <Title level={5} style={{marginTop: 0}}><strong>{course.title}</strong></Title>
                                )}

                                <Row type="flex" justify="end">
                                    <Space>
                                        <Button icon={<ClockCircleOutlined/>}>{course.time}</Button>
                                    </Space>
                                </Row>
                            </Card>
                        {course && (
                            <Tabs defaultActiveKey="1" items={[
                                {
                                    key: '1',
                                    label: 'Kirjeldus',
                                    children: <div>
                                        {course.description.split('\n').map((line, index) => (
                                            <React.Fragment key={index}>
                                                {line}
                                                <br/>
                                            </React.Fragment>
                                        ))}
                                    </div>,
                                },
                                course.type === 'course' && {
                                    key: '2',
                                    label: 'Ülesanded',
                                    children:
                                        <div>
                                            <Progress
                                                strokeColor={{'0%': '#ee7752', '50%': '#e73c7e'}}
                                                percentPosition={{type: 'hidden'}}
                                                percent={round((completedTasksCount / course.tasks.length) * 100)}
                                                size="small"
                                            />

                                            <List
                                                itemLayout="horizontal"
                                                dataSource={course.tasks}
                                                renderItem={(task) => {
                                                    return (
                                                        <TaskItem task={task} handleTaskToggle={handleTaskToggle}
                                                                  setCourse={setCourse}/>
                                                    )
                                                }}
                                            />
                                        </div>
                                },
                                course.type === 'course' && {
                                    key: '3',
                                    label: 'Videod',
                                    children:
                                        <Courses type={"vertical"}/>
                                }
                            ].filter(Boolean)}/>
                        )}
                        </Col>
                    </Row>
                </Col>
            </Row>
        </div>
    );
};

const TaskItem = ({task, handleTaskToggle, setCourse}) => {
    const [note, setNote] = useState(task.note || '');
    const [visible, setVisible] = useState(task.note);
    const {t} = useTranslation();

    // Handle textarea change
    const handleChange = (e) => {
        setNote(e.target.value);
    };

    // Handle note submission
    const handleSubmit = async () => {
        if (!note.trim()) {
            message.error('Note cannot be empty!');
            return;
        }


        Api.post(`/v2/tasks/${task.id}/note`, {note: note}).then((res) => {
            res.data.isAvailable = true;

            setCourse(res.data);
            message.success(t('note.saved'));
        }).catch((err) => {
            console.log(err);
        });
    };

    const clickButton = (e) => {
        e.stopPropagation();
        e.preventDefault();

        setVisible(true);
    }

    // Handle blur event to save the note
    const handleBlur = () => {
        if (note.trim()) {
            handleSubmit();
        }
    };

    return (
        <div>
            <List.Item
                actions={!visible ? [<Button icon={<MessageOutlined/>} onClick={clickButton}>{t('add.note')}</Button>] : ''}
            >
                <Checkbox
                    defaultChecked={task.completed}
                    onChange={() => handleTaskToggle(task)}
                >
                    {task.description}
                </Checkbox>
            </List.Item>

            {visible && (
                <div>
                    <TextArea
                        style={{maxWidth: 420, marginTop: 12}}
                        rows={4}
                        value={note}
                        onChange={handleChange}
                        onBlur={handleBlur} // Trigger save on blur
                        placeholder={t('note.placeholder')}
                    />
                </div>
            )}
        </div>
    );
};

export default SingleCourseView;
