import {Divider, message, Row, Space, Typography} from "antd";
import PaymentMethods from "../payment/payment-methods";
import SheetModal from "../blocks/sheet-modal";
import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {config, paymentUrl, redirect, trackEvent} from "../../helpers";

const {Text, Title} = Typography;

const PurchaseChallengeModal = ({showPurchase, setShowPurchase, challenge}) => {
    const {t} = useTranslation();
    const [loading, setLoading] = useState(false);

    const continuePurchase = (paymentMethod) => {
        redirect(paymentUrl({
            payment_method: paymentMethod,
            product_id: challenge.product.id,
        }));
    }

    return (
        <SheetModal footer={false} open={showPurchase} close={() => setShowPurchase(false)}>
            <div>
                <Divider orientation="center">
                    <Title style={{margin: 0}} level={4}>Osta ligipääs</Title>
                </Divider>

                {challenge && (
                    <div>
                        <Space direction="vertical">
                            <Text>Pakett: <strong>{challenge.name}</strong></Text>
                            <Text>Hind: <strong>{challenge.product.price} {challenge.product.currency}</strong></Text>
                            <Text>Kestvus: <strong>{t('period-' + challenge.product.period)}</strong></Text>
                        </Space>

                        <Row type="flex" justify="center" className="text-center">
                            <PaymentMethods product={challenge.product} setPaymentMethod={continuePurchase}
                                            paymentMethods={challenge.product.payment_methods}/>
                        </Row>
                    </div>
                )}
            </div>
        </SheetModal>
    )
}

export default PurchaseChallengeModal;