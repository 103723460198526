import React from "react";
import {Button, Col, Modal, Row} from "antd";
import {Link} from 'react-router-dom'
import {connect} from "react-redux";
import {dateDifference, isEmpty, redirect} from "../../helpers";
import {withTranslation} from 'react-i18next';

const {confirm} = Modal;

class ItunesPayment extends React.Component {
    isRecurringActive = () => {
        return (this.props.recurring.status === 'active');
    };

    showCancelRecurringConfirm = (url) => {
        confirm({
            title: this.props.t('payments.cancel.title'),
            content: this.props.t('payments.cancel.content'),
            okText: this.props.t('payments.cancel.yes-button'),
            okType: 'danger',
            cancelText: this.props.t('payments.cancel.no-button'),
            autoFocusButton: null,
            onOk() {
                redirect(url);
            },
            onCancel() {
                //console.log('Cancel');
            },
        });
    };

    cancelInfo = () => {
        const {t} = this.props;
        Modal.info({
            className: "cancel-subscription-modal",
            title: t('products.modal.title'),
            content: (
                <div>
                    <p>{t('products.modal.content')}</p>
                </div>
            ),
            onOk() {},
        });
    };

    // Allow recurring only if less than 4 days left
    isRecurringAvailable = () => {
        if (!isEmpty(this.props.access) && !this.isRecurringActive()) {
            if (dateDifference(this.props.access.end_date) >= 4) {
                return false;
            }
        }

        return true;
    };

    onCancel = () => {
        if (this.props.recurring.cancel_url) {
            return this.showCancelRecurringConfirm(this.props.recurring.cancel_url);
        } else {
            if (this.props.recurring.paysys_id = 'apple') {
                return this.props.history.push('/settings/itunes');
            }
        }

        return this.cancelInfo();
    };

    render() {
        const {t} = this.props;

        return (
            <Row className="ios-payment-section text-center">
                <Col span={24}>
                    {
                        this.isRecurringActive() ? (
                            <Button size="large" className="main-btn" onClick={this.onCancel} type="danger" ghost>{t('products.cancel')}</Button>
                        ) : (
                            <Button className="main-btn" type="primary" size="large" disabled={!this.isRecurringAvailable() || this.props.access_loading}>
                                <Link to={'/settings/itunes'} className="account-back">{t('payments.subscription.ios-button')}</Link>
                            </Button>
                        )
                    }
                </Col>
            </Row>
        );
    }
}

const mapStateToProps = (state) => ({
    access: state.access.data,
    access_loading: state.access.loading,
    recurring: state.access.data.recurring ? state.access.data.recurring : {},
    products: state.products.data,
});

export default withTranslation()(connect(mapStateToProps)(ItunesPayment));