import {Badge, Col, Input, List, Modal, Row, Skeleton, Tag} from "antd";
import {useTranslation} from "react-i18next";
import {useEffect, useState} from "react";
import Api from "../../api";
import {config, convert} from "../../helpers";
import Icon, {CloseOutlined} from "@ant-design/icons";
import {SearchIcon} from "../../icons";
import {useSelector} from "react-redux";
import FocusLock from "react-focus-lock";

const SearchIngredientsModal = ({visible, close, data, selectIngredient}) => {
    const {t} = useTranslation();
    const [ingredients, setIngredients] = useState([]);
    const [keywords, setKeywords] = useState('');
    const [loading, setLoading] = useState(false);
    const user = useSelector(state => state.user.data);
    const [focus, setFocus] = useState(true);

    useEffect(() => {
        // Disable focuslock
        setTimeout(() => {
            setFocus(!visible);
        }, 1000);
    }, [visible])

    useEffect(() => {
        if (data.id) {
            fetchIngredients();
        }
    }, [data.id, data.energy, data.simple]);

    useEffect(() => {
        if (!visible && keywords) {
            emptySearch();
        }
    }, [visible]);

    const fetchIngredients = () => {
        setLoading(true);

        Api.get('/v2/ingredients/' + data.id + '/substitutes?energy=' + data.energy + '&per_page=1000' + (data.simple ? '&simple=1' : '')).then(res => {
            setIngredients(res.data.data);
            setLoading(false);
        })

    };

    const emptySearch = () => {
        setKeywords('');
    };

    const onChange = (e) => {
        setKeywords(e.target.value);
    };

    const getUnit = () => {
        return (user.unit_system === config('unit_system.imperial')) ? 'oz' : 'g';
    };

    const getAmount = (amount) => {
        return (user.unit_system === config('unit_system.imperial')) ? convert.gramsToOunces(amount) : amount;
    };

    let filteredIngredients = ingredients;

    // Filter by keyword
    if (ingredients && keywords) {
        filteredIngredients = ingredients.filter((ingredient) => {
            return ingredient.original_name.toLowerCase().indexOf(keywords.toLowerCase()) !== -1;
        });
    }

    return (
        <Modal
            destroyOnClose={true}
            className="search-ingredients-modal"
            title={t('recipes.ingredients.title')}
            open={!!visible}
            onOk={close}
            onCancel={close}
            footer={false}
        >
            <Row type="flex" justify="center">
                <Col span={24} md={16}>
                    <div>
                        <FocusLock disabled={!focus}>
                            <Input
                                rootClassName="search-ingredient"
                                autoFocus={true}
                                onChange={onChange}
                                placeholder={t('recipes.ingredients.search-placeholder')}
                                prefix={<Icon className="custom-icon" component={SearchIcon}/>}
                                suffix={keywords ? <Icon onClick={emptySearch} className="icon-close custom-icon"
                                                         component={CloseOutlined}/> : ''}
                                value={keywords}
                                size="large"
                            />
                        </FocusLock>
                    </div>
                </Col>
            </Row>

            <br/>

            <div>
                <Row type="flex" justify="center" className="ingredient-substitutes">
                    <Col span={24} md={16}>
                        {filteredIngredients && (
                            <List
                                locale={{ emptyText: t('recipes.ingredients.not-found') }}
                                dataSource={filteredIngredients}
                                renderItem={ingredient => (
                                    <List.Item onClick={() => selectIngredient(ingredient)}>
                                        {getAmount(ingredient.amount)}{getUnit()} {ingredient.original_name} {!!ingredient.is_promoted ? <Tag color="success" bordered={false}>{t('activity.new')}</Tag> : ''}
                                    </List.Item>
                                )}
                            />
                        )}

                        {loading && (
                            <Skeleton paragraph={{rows: 4}}/>
                        )}
                    </Col>
                </Row>
            </div>
        </Modal>
    )
}

export default SearchIngredientsModal;