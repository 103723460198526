import React from 'react';
import {Col, Row, Space, Tag, Tooltip} from 'antd';
import Icon from "@ant-design/icons";
import {config, convert} from '../../helpers.js';
import {round} from "../../helpers";
import {BarcodeIcon, LeafIcon, SwapIcon} from "../../icons";
import {useSelector} from "react-redux";
import {useTranslation} from "react-i18next";

const Ingredient = ({ingredient, multiplier, isPrivate, hideAmount, showSearchIngredients, disabled}) => {
    const {t} = useTranslation();
    const user = useSelector(state => state.user.data);
    const hasSubstitutes = ingredient.substitute_group > 0;
    const isSwapped = ingredient.original_id !== ingredient.id;
    const isCustomIngredient = !!ingredient.user_id;

    const ingredientName = () => {
        const unit = isImperialSystem() ? t('unit.oz') : t('unit.g');
        const ingredientAmount = isImperialSystem() ? convert.gramsToOunces(ingredient.amount) : ingredient.amount;
        let name = ingredient.original_name;
        let amount = '';
        let custom_amount = multiplier ? round(ingredient.custom_amount * multiplier, 1) : ingredient.custom_amount;
        let custom_unit = ingredient.custom_unit_name;

        // Display no amount for private recipes
        if (isPrivate) {
            return name;
        }

        // Convert amount to oz
        if (isImperialSystem()) {
            if (ingredient.cooked_amount) {
                ingredient.cooked_amount = convert.gramsToOunces(ingredient.cooked_amount);
            }
        }

        // Amount
        if (canDisplayAmount()) {
            if (multiplier) {
                amount = round(ingredientAmount * multiplier) + unit;
            } else {
                amount = ingredientAmount + unit;
            }
        }

        // Cooked Amount
        if (ingredient.cooked_amount) {
            return amount + ' (' + ingredient.cooked_amount + unit + ' ' + t('recipes.ingredients.cooked') + ') ' + name;
        }

        if (canDisplayCustomAmount()) {
            if (canDisplayHalf()) {
                custom_amount = '0.5';
            }

            return amount + ' (' + custom_amount + ' ' + custom_unit + ') ' + name;
        }

        return amount + ' ' + name;
    };

    const canDisplayAmount = () => {
        if (hideAmount) {
            return false;
        }

        if (!ingredient.display_format) {
            return true;
        }

        if (isImperialSystem() && ingredient.display_format & config('unit_system.display_amount_imperial')) {
            return true;
        }

        if (!isImperialSystem() && ingredient.display_format & config('unit_system.display_amount')) {
            return true;
        }

        return false;
    };

    const canDisplayCustomAmount = () => {
        if (!hasCustomUnit()) {
            return false;
        }

        if (!ingredient.display_format) {
            return true;
        }

        if (isImperialSystem() && ingredient.display_format & config('unit_system.display_custom_amount_imperial')) {
            return true;
        }

        if (!isImperialSystem() && ingredient.display_format & config('unit_system.display_custom_amount')) {
            return true;
        }

        return false;
    };

    const canDisplayHalf = () => {
        return ((ingredient.no_limit || ingredient.is_egg) && ingredient.custom_amount <= 0.7 && ingredient.custom_amount > 0);
    };

    const hasCustomUnit = () => {
        return (ingredient.custom_unit > 0 || ingredient.custom_unit_name);
    };

    const isImperialSystem = () => {
        return ((config('unit_system.imperial') == user.unit_system) && config('feature.imperial_ingredients'))
    };

    return (
        <Row type="flex" className={"recipe-ingredient " + (isSwapped ? "swapped" : "")} align="middle">
            <Col span={20}>
                <Row type="flex" align="middle">
                    <Space>
                        {isSwapped ? (
                            <Tooltip rootClassName="tooltip-in-modal" title={t('ingredient.swapped')}>
                                {ingredientName()}
                            </Tooltip>
                        ) : (
                            ingredientName()
                        )}

                        {ingredient.logo_image && (
                            <a target="_blank" rel="noreferrer" href={ingredient.logo_landing_page}>
                                <img className="ingredient-icon partner" src={ingredient.logo_image} alt="logo"/>
                            </a>
                        )}
                    </Space>

                    {isCustomIngredient && (
                        <Tooltip rootClassName="tooltip-in-modal" title={t('custom-ingredient.tooltip.content')}>
                            <Icon className="ingredient-icon" component={BarcodeIcon}/>
                        </Tooltip>
                    )}

                    {ingredient.no_limit && (
                        <Tooltip rootClassName="tooltip-in-modal" title={t('meal-plan.freeware.tips.tip1')}>
                            <Icon className="ingredient-icon" component={LeafIcon}/>
                        </Tooltip>
                    )}

                    {
                        ingredient.order_url && (
                            <Tag className="buy-ingredient" color={"#0eb8ae"}>
                                <a target="_blank" href={ingredient.order_url}>{t('recipes.ingredients.buy')}</a>
                            </Tag>
                        )
                    }

                </Row>
            </Col>

            {
                (hasSubstitutes && showSearchIngredients && !isPrivate) && !disabled && (
                    <Col span={4} className="swap-ingredient">
                        <div
                            onClick={() => showSearchIngredients(ingredient.original_id ? ingredient.original_id : ingredient.id, ingredient.energy)}>
                            <Tooltip rootClassName="tooltip-in-modal hide-mobile" placement="top"
                                     title={t('recipes.ingredients.change')}>
                                <Icon className="custom-icon" component={SwapIcon}/>
                            </Tooltip>
                        </div>
                    </Col>
                )
            }
        </Row>
    )
}
export default Ingredient;