import React, {useEffect, useState} from "react";
import {Avatar, Col, Row, Table, Pagination} from "antd";
import Icon, {TrophyOutlined, UserOutlined} from "@ant-design/icons";
import {withTranslation} from 'react-i18next';
import {useSelector} from "react-redux";
import {filterObject} from "../../helpers";
import Api from "../../api";
import UserProfileModal from "../chatroom/user-profile-modal";
import {useResizeEffect} from "../../resize-effect";
import {useParams} from "react-router-dom";

const {Column} = Table;

const ActivityLeaderboard = ({t, hasJoinedCompetition}) => {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [perPage, setPerPage] = useState(25);
    const [showUserModal, setShowUserModal] = useState(false);
    const [topParticipants, setTopParticipants] = useState([]); // Top 3 participants
    const [userPlacement, setUserPlacement] = useState({}); // Top 3 participants
    const params = useParams();
    const user = useSelector(state => state.user.data);

    useEffect(() => {
        loadCompetition(params.id, currentPage);
    }, [hasJoinedCompetition, currentPage]);

    const loadCompetition = (id, page = 1) => {
        setLoading(true);

        Api.get(`/v3/competitions/${id}?page=${page}`)
            .then(response => {
                const {participants} = response.data.data;
                setTotalPages(participants.last_page || 1);
                setPerPage(participants.per_page || 25);
                setUserPlacement(response.data.data.current_user_position);

                const allParticipants = formatParticipants(participants.data);

                if (page === 1) {
                    const top3 = allParticipants.slice(0, 3); // Extract top 3 participants

                    setTopParticipants(top3); // Save the top 3
                }

                setData(allParticipants); // Set full data for pagination
            }).finally(() => setLoading(false));
    };

    const formatParticipants = (participants) => {
        return participants.map((participant, i) => ({
            key: i + 1,
            user_id: participant.user?.user_id,
            name: participant.user?.name,
            avatar: participant.user?.avatar,
            user: participant.user,
            steps: participant.steps_count,
        }));
    };

    const handlePageChange = (page) => {
        setCurrentPage(page);
    };

    const renderHeader = () => {
        return <>{t('user.placement')} <strong>#{userPlacement.position}</strong></>
    }

    return (
        <Col span={24} lg={24}>
            <UserProfileModal visible={!!showUserModal}
                              setVisible={setShowUserModal} data={showUserModal}/>

            <LeaderboardPodium data={topParticipants} setShowUserModal={setShowUserModal}/>

            {data.length > 3 && (
                <>
                    <Table dataSource={currentPage === 1 ? data.slice(3) : data} size="middle" bordered={false} loading={loading}
                           pagination={false}
                           title={userPlacement ? renderHeader : undefined}
                           rowClassName={(record) => record.user_id === user.user_id ? 'active-row' : ''}>

                        <Column title={"#"} dataIndex="key" key="key" width={20}
                                render={(key, record) => (
                                    <div>{(currentPage - 1) * perPage + key}</div>
                                )}
                        />

                        <Column title={t('blogs.edit-profile.name')} dataIndex="name" key="name"
                                render={(name, record) => (
                                    <div style={{cursor: "pointer"}} onClick={() => setShowUserModal(record)}>
                                        <Avatar size="small" icon={<UserOutlined/>}
                                                src={record.avatar}/> {record.user?.name}
                                    </div>
                                )}
                        />

                        <Column className="text-right" title={t('activity.steps')} dataIndex="steps" key="steps"
                                sorter={(a, b) => b.steps - a.steps}
                                render={(text) => text ? text.toLocaleString() : 0}/>
                    </Table>

                    <Row type="flex" justify="center">
                        <Pagination
                            showSizeChanger={false}
                            current={currentPage}
                            pageSize={perPage}
                            total={totalPages * perPage}
                            onChange={handlePageChange}
                            style={{marginTop: 16, textAlign: 'center'}}
                        />
                    </Row>
                </>
            )}
        </Col>
    );
}

const LeaderboardPodium = ({data, setShowUserModal}) => {
    const isMobile = useResizeEffect();

    const renderPlacement = (placement, medal) => {
        const index = placement - 1;
        if (!data[index]) return null;

        return (
            <Col className="podium" span={8} md={placement === 1 ? 6 : 4}>
                <div style={{display: "flex", flexDirection: "column", alignItems: "center"}}>
                    <Avatar icon={<UserOutlined/>} style={{cursor: "pointer"}}
                            onClick={() => setShowUserModal(data[index])} src={data[index].avatar}
                            size={placement === 1 ? 84 : 64}/>
                    <span className="medal silver">{medal}</span>
                </div>
                <div style={{cursor: "pointer"}} onClick={() => setShowUserModal(data[index])}
                     className="name">{data[index].name}</div>
                <div className="score">{data[index].steps ? data[index].steps.toLocaleString() : 0} <br/> sammu</div>
                <div className={"podium-rank rank-" + placement}>{placement}</div>
            </Col>
        );
    };

    return (
        <Row className="podium-wrapper" justify="center" gutter={isMobile ? 10 : 20}>
            {renderPlacement(2, '🥈')}
            {renderPlacement(1, '🥇')}
            {renderPlacement(3, '🥉')}
        </Row>
    );
};

export default withTranslation()(ActivityLeaderboard);
