export const getRecipeFilters = (user) => {
    let filters = [
        //{name: "is_favorite_author"},
        {name: 'is_favorite'},
        {name: 'is_custom'},
        {name: "max_ingredients"},
        {name: 'is_user_public'},
        {name: "recent"},
        {name: "meal_type_1"},
        {name: "meal_type_6"},
        {name: "meal_type_8"},
        {name: "desserts", category: 1},
        {name: "main_course", category: 2},
        {name: "smoothies", category: 4},
        {name: "porridges", category: 5},
        {name: "snacks", category: 6},
        {name: "salads", category: 7},
        {name: "vegan"},
        {name: "vegetarian"},
        {name: "from_store"},
        {name: "basic"},
        {name: "soups", category: 3},
        {name: "lactose_free"},
        {name: "gluten_free"},
        {name: 'is_disliked'}
    ];

    if (!user.intolerances || user.intolerances.show_restaurant_meals) {
        filters.push({name: 'restaurant_meal'});
    }

    return filters;
};
