import {
    CartesianGrid,
    Legend,
    Line,
    LineChart,
    ResponsiveContainer,
    XAxis,
    YAxis,
    Tooltip as ChartTooltip,
    ComposedChart, Area, BarChart, Bar, Cell
} from "recharts";
import {Card, Col, Divider, Typography} from "antd";
import React from "react";
import moment from "moment";
import {useTranslation} from "react-i18next";

const {Title, Text} = Typography;

const BingoChart = ({bingo}) => {
    const {t} = useTranslation();

    // Process data for the chart
    const chartDataMap = new Map();

    bingo.forEach(entry => {
        entry.items.forEach(item => {
            if (item.completed && item.completed.status) {
                const formattedDate = moment(item.completed.created_at).format("DD-MM-YYYY");

                if (!chartDataMap.has(formattedDate)) {
                    chartDataMap.set(formattedDate, { date: formattedDate, tasksCompleted: 0 });
                }

                chartDataMap.get(formattedDate).tasksCompleted += 1;
            }
        });
    });

    const chartData = Array.from(chartDataMap.values());

    const formatXAxis = (tickItem) => {
        return moment(tickItem, 'DD-MM-YYYY').format('Do MMM');
    };

    return (
        <Card className="bingo-chart">
            <Divider orientation="left">
                <Title style={{margin: 0}} level={4}>Tehtud ülesanded</Title>
            </Divider>

            <ResponsiveContainer className="bingo-chart-container" width='100%' height={160}>
                <BarChart
                    data={chartData}
                >
                    <CartesianGrid strokeDasharray="3 5"/>

                    <XAxis dataKey="date" tickFormatter={formatXAxis}/>

                    <YAxis width={16} domain={[0, 'auto']} allowDecimals={false} tickCount={3} />

                    <ChartTooltip labelFormatter={(value) => moment(value, 'DD-MM-YYYY').format('DD.MM.YYYY')} cursor={false} />

                    <Bar name={t('tasks.completed')} dataKey="tasksCompleted" barSize={6} radius={[6, 6, 0, 0]} minPointSize={2}>
                        {chartData.map((entry, index) => {
                            const getBarColor = () => {
                                if (entry.tasksCompleted > 2) {
                                    return "rgba(14,184,174,1)";
                                }

                                if (entry.tasksCompleted > 1) {
                                    return "rgba(14,184,174,0.6)";
                                }

                                return "rgba(14,184,174,0.3)";
                            }

                            return (
                                <Cell cursor="pointer" fill={getBarColor()} key={`cell-${index}`}/>
                            )
                        })}
                    </Bar>
                </BarChart>
            </ResponsiveContainer>
        </Card>
    );
};

export default BingoChart;
